import * as React from "react";
import { Create, ReferenceField, ReferenceInput, SelectInput, SimpleForm, TextField } from 'react-admin';
import { PaymentForm } from "./payment-form";


export const PaymentCreate = ({ permissions, ...props }) =>  (
        <Create { ...props }>
            <SimpleForm>
                <PaymentForm/>
                <ReferenceInput perPage="200" source="userId" reference="user"><SelectInput optionText="email"/></ReferenceInput>
            </SimpleForm>
        </Create>
    )

