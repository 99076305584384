import * as React from "react";
import {
    Edit,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    Create,
    useGetList,
    AutocompleteArrayInput
} from 'react-admin';

export const IntegrationCreate = ({ permissions, ...props }) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <ReferenceInput perPage="200" source="systemIntegrationId" reference="system">
                    <SelectInput optionText={(r) => `${r.systemName} ${r.login || r.iikoBizUser}` } />
                </ReferenceInput>
                <ReferenceInput perPage="200" source="messengerIntegrationId" reference="messenger">
                    <SelectInput optionText={(r) => `${r.messengerName} ${r.botName}` } />
                </ReferenceInput>
                <ReferenceInput perPage="200" source="userId" reference="user">
                    <SelectInput optionText="email"/>
                </ReferenceInput>
            </SimpleForm>
        </Create>
    );
}