import * as React from "react";
import { Create, SimpleForm, TextInput, ReferenceInput, SelectInput, SelectField } from 'react-admin';
import {
    required,
    minLength,
    email,
} from 'react-admin';
import { useState } from "react";
import { SystemForm } from "./systemForm";


export const SystemCreate = ({ permissions, ...props }) =>  (
        <Create { ...props }>
            <SimpleForm>
                <SystemForm/>
                <ReferenceInput perPage="200" source="userId" reference="user"><SelectInput optionText="email"/></ReferenceInput>
            </SimpleForm>
        </Create>
    )

