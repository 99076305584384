
import { getIdentity } from './helpers/identity'
const authUrl = `${process.env.REACT_APP_ACCESS_URL}/auth`

const _refresh = () => {
  const refreshToken = localStorage.getItem('refreshToken')

  if (!refreshToken) {
    return Promise.reject()
  }
  
  const request = new Request(`${authUrl}/refresh`, {
    method: 'POST',
    body: JSON.stringify({ refreshToken }),
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  });
  return fetch(request)
    .then(response => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }

      return response.json();
    })
    .then(({ accessToken, refreshToken, tokens }) => {
      if (tokens) {
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
      } else {
        throw new Error('Unable to refresh token')
      }
    })
}

const permissions = {
  users: {
    list: ['admin', 'partner'],
    edit: {
      general: ['admin', 'partner'],
      self: ['admin'],
      available: {
        admin: ['admin'],
        partner: ['admin', 'partner'],
        user: ['admin', 'partner'],
      }
    },
    create: {
      general: ['admin', 'partner'],
      available: {
        admin: ['admin'],
        partner: ['admin', 'partner'],
        user: ['admin', 'partner'],
      }
    },
  },
}

export default {
  login: ({ username, password }) => {
    const request = new Request(`${authUrl}/login`, {
      method: 'POST',
      body: JSON.stringify({ email: username, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ accessToken, refreshToken, tokens }) => {
        if (tokens) {
          localStorage.setItem('accessToken', accessToken);
          localStorage.setItem('refreshToken', refreshToken);
        } else {
          throw new Error('Invalid email or password')
        }
      });
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      return _refresh()
    }

    return Promise.resolve();
  },
  checkAuth: () => {
    return localStorage.getItem('accessToken') ? Promise.resolve() : Promise.reject();
  },
  logout: () => {
    localStorage.removeItem('user');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    return Promise.resolve();
  },
  getIdentity: (error) => { 
    return getIdentity()
      .then((user) => {
        const fullName = user.firstname && user.lastname 
          ? `${user.firstname} ${user.lastname}` 
          : user.email

        return { id: user.id, fullName }
      }) 
  },
  getPermissions: () => {
    return getIdentity()
      .then(user => {
        return {
          role: user.role,
          can: (...varArg) => {
            if (!varArg?.length) {
              return false;
            }

            const action = varArg.join('.');

            const roles = action.split('.').reduce((o, k) => {
              return o && o[k];
            }, permissions);

            if (!roles?.length) {
              return false;
            }

            return roles.includes(user.role);
          },
        };
      })
  }
};