import { ReferenceInput, SelectField, SelectInput, TextInput } from "react-admin";
import * as React from "react";

export const GeoForm = ({permissions,  ...props}) => {
    return <div {...props}>
        <TextInput fullWidth source="name"/>
        <SelectInput source="provider" choices={ [
            { id: 'yandex', name: 'yandex' },
            { id: 'google', name: 'google' },
            { id: 'openstreetmap', name: 'open street map' },
        ]} { ...props }/>
        <TextInput fullWidth source="apiKey"/>
        <TextInput label='Локаль' helperText='Например, ru_RU' fullWidth source="locale"/>
        <ReferenceInput perPage="200" source="userId" reference="user"><SelectInput optionText="email"/></ReferenceInput>
        {/*<SelectInput source='locale' choices={[*/}
        {/*    { id: 'ru_RU', name: 'ru_RU' },*/}
        {/*    { id: 'en-US', name: 'en-US' },*/}
        {/*]} />*/}
    </div>
}

//  'yandex' = 'yandex',
//     'google' = 'google',
//     'open street map' = 'open street map',