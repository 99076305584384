import * as React from "react";
import {
    DateField,
    List,
    Datagrid,
    TextField,
    ReferenceField,
    NumberField,
    useListContext,
    TextInput,
    NumberInput,
    TopToolbar,
    ExportButton,
    DateInput,
    SelectInput,
    AutocompleteArrayInput,
    ReferenceArrayInput
} from 'react-admin';
import { Form } from "react-final-form";
import { Box, Button, InputAdornment } from "@material-ui/core";
import ContentFilter from "@material-ui/icons/FilterList";

const OrderFilterForm = () => {
    const {
        displayedFilters,
        filterValues,
        setFilters,
        hideFilter
    } = useListContext();

    if (!displayedFilters.main) return null;

    const onSubmit = (values) => {
        if (values.localCreationTime) {
            values.localCreationTime = new Date(values.localCreationTime);
        }

        if (Object.keys(values).length > 0) {
            setFilters(values);
        } else {
            hideFilter("main");
        }
    };

    const resetFilter = () => {
        setFilters({}, []);
    };

    return (
        <div>
            <Form onSubmit={ onSubmit } initialValues={ filterValues }>
                { ({ handleSubmit }) => (
                    <form onSubmit={ handleSubmit }>
                        <Box display="flex" alignItems="flex-end" flexWrap="wrap" mb={ 1 }>
                            <Box component="span" mr={ 2 }>
                                <NumberInput
                                    resettable
                                    helperText={ false }
                                    source="number"
                                    label="Номер заказа"
                                />
                            </Box>
                            <Box component="span" mr={ 2 }>
                                <SelectInput
                                    resettable
                                    label="Статус заказа"
                                    helperText={ false }
                                    source="status"
                                    choices={ [
                                        { id: 'Unconfirmed', name: 'Unconfirmed' },
                                        { id: 'WaitCooking', name: 'WaitCooking' },
                                        { id: 'ReadyForCooking', name: 'ReadyForCooking' },
                                        { id: 'CookingStarted', name: 'CookingStarted' },
                                        { id: 'CookingCompleted', name: 'CookingCompleted' },
                                        { id: 'Waiting', name: 'Waiting' },
                                        { id: 'OnWay', name: 'OnWay' },
                                        { id: 'Delivered', name: 'Delivered' },
                                        { id: 'Closed', name: 'Closed' },
                                        { id: 'Cancelled', name: 'Cancelled' },
                                        { id: 'Error', name: 'Error' },
                                        { id: 'RepeatOrderError', name: 'RepeatOrderError' },
                                        { id: 'Creating', name: 'Creating' },
                                    ] }/>
                            </Box>
                            <Box component="span" mr={ 2 }>
                                <TextInput
                                    helperText={ false }
                                    source="phone"
                                    label="Номер телефона"
                                />
                            </Box>
                            <Box component="span" mr={ 2 }>
                                <DateInput
                                    resettable
                                    helperText={ false }
                                    source="localCreationTime_gte"
                                    label="Время создания от"
                                />
                            </Box>
                            <Box component="span" mr={ 2 }>
                                <DateInput
                                    resettable
                                    helperText={ false }
                                    source="localCreationTime_lte"
                                    label="Время создания до"
                                />
                            </Box>

                            <Box component="span" mr={ 2 }>
                                <ReferenceArrayInput source='organizationId' reference="organization">
                                    <AutocompleteArrayInput allowNull helperText={ false } optionText="adminName" />
                                </ReferenceArrayInput>
                            </Box>

                            <Box component="span" mr={ 2 } mb={ 1.5 }>
                                <Button variant="outlined" color="primary" type="submit">
                                    Filter
                                </Button>
                            </Box>
                            <Box component="span" mb={ 1.5 }>
                                <Button variant="outlined" onClick={ resetFilter }>
                                    Close
                                </Button>
                            </Box>
                        </Box>
                    </form>
                ) }
            </Form>
        </div>
    );
};

export const OrderFilterButton = () => {
    const { showFilter } = useListContext();
    return (
        <Button
            size="small"
            color="primary"
            onClick={ () => showFilter("main") }
            startIcon={ <ContentFilter/> }
        >
            Filter
        </Button>
    );
};


export const OrderListActions = () => {
    return (
        <Box width="100%">
            <TopToolbar>
                <OrderFilterButton/>
                <ExportButton/>
            </TopToolbar>
            <OrderFilterForm  />
        </Box>
    )
};

export const TransportOrderDataGrid = ({ permissions, ...props }) => {
    const { filterValues, currentSort, perPage } = useListContext();

    const goToFilters = (record, reference) => {

        const { order, field } = currentSort;
        const newFilterValues = { ...filterValues, organizationId: [...(filterValues?.organizationId || [])] };

        if (newFilterValues?.organizationId?.length) {
            if (newFilterValues.organizationId.includes(record.organizationId)) {
                return "#";
            }
            newFilterValues.organizationId.push(record.organizationId);
        } else {
            newFilterValues.organizationId = [record.organizationId];
        }

        const resultFilters = {
            displayedFilters: JSON.stringify({ main: true }),
            filter: JSON.stringify(newFilterValues),
            order,
            sort: field,
            page: 1,
            perPage
        }

        const result = "?" + (new URLSearchParams(resultFilters)).toString();

        console.log(`filter result: ${ result }`);

        return result;
    }


    return (
        <Datagrid rowClick="show">
            <TextField source="id"/>
            <NumberField label="Номер" source="number"/>
            <TextField label="Тип доставки" source="orderServiceType"/>
            <TextField label="Телефон" source="phone"/>
            <TextField label="Статус" source="status"/>
            <DateField label="Дата создания" source="localCreationTime" showTime/>
            <NumberField label="Стоимость" source="sum"/>
            <NumberField label="Доставка" source="deliveryPrice"/>
            <ReferenceField link={ goToFilters } label="Организация" source="organizationId" reference="organization">
                <TextField source="adminName"/>
            </ReferenceField>
        </Datagrid>
    )
}


export const TransportOrderList = ({ permissions, ...props }) => {
    return (
        <List bulkActionButtons={ false } actions={ <OrderListActions/> }
              sort={ { field: 'localCreationTime', order: 'DESC' } } { ...props }>
            <TransportOrderDataGrid/>
        </List>
    );
}