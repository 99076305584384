import * as React from "react";
import {
    Show,
    TextField,
    SimpleShowLayout, TextInput
} from 'react-admin';

export const PaymentShow = props => (

    <Show { ...props }>
        <SimpleShowLayout>
            <TextField source="id"/>
            <TextField source="name"/>
            <TextField label="username" source="userName"/>
            <TextField source="cryptoKey"/>
            <TextField source="password"/>
            <TextField source="merchant"/>
            <TextField source="serviceAddress"/>
        </SimpleShowLayout>
    </Show>
);