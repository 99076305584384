import * as React from "react";
import { useState, useCallback } from 'react';
import {
    Show,
    TabbedShowLayout,
    TextField,
    Tab,
    EditButton,
    Datagrid,
    Pagination,
    ShowButton,
    NumberField,
    TopToolbar,
    ReferenceField,
    BooleanField,
    ListButton,
    useShowController,
    ListContextProvider,
    useListContext
} from 'react-admin';
import { keyBy } from "lodash"
import {
    required,
    minLength,
    email,
} from 'react-admin';
import { ModifierEditButton } from "./modifiers/edit"

const ProductShowActions = ({ basePath, data, resource, organizationId }) => (
    <TopToolbar>
        <ListButton to={ `/group/${ organizationId }/${ data.parentGroup }/show/1` } label="product list"/>
    </TopToolbar>
);

export const ProductShow = props => {

    props = {
        ...props,
        basePath: props.match.url,
        id: decodeURIComponent(props.match.params.id),
        organizationId: decodeURIComponent(props.match.params.organizationId)
    }

    const [version, setVersion] = useState(0);
    const handleChange = useCallback(() => setVersion(version + 1), [version]);

    const { record } = useShowController(props)
    let modifiers = record?.modifiers || []
    const groupModifiers = record?.groupModifiers || []
    const childModifiers = []

    for (let gModifier of groupModifiers) {
        for (let cModifier of gModifier.childModifiers) {
            cModifier.parent = {
                ...gModifier,
                childModifiers: undefined
            }

            childModifiers.push(cModifier)
        }
    }

    modifiers = modifiers.concat(childModifiers)

    if (!record?.parentGroup) {
        return (<></>);
    }

    const toParentGroup = (record, reference) => {
        return `/${ reference }/${ props.organizationId }/${ record.parentGroup }/show`
    }

    return (
        <Show actions={ <ProductShowActions organizationId={ props.organizationId }/> } { ...props } >
            <TabbedShowLayout>
                <Tab label="summary">
                    <ReferenceField source="parentGroup" reference="group" link={ toParentGroup }><TextField
                        source="name"/></ReferenceField>
                    <TextField source="id"/>
                    <TextField source="name"/>
                    <TextField source="description"/>
                    <TextField source="additionalInfo"/>
                    <TextField source="seoDescription"/>
                    <TextField source="seoText"/>
                    <TextField source="seoKeywords"/>
                    <TextField source="seoTitle"/>
                </Tab>
                <Tab label="modifiers">
                    <ListContextProvider
                        value={ {
                            resource: 'modifiers',
                            data: keyBy(modifiers, 'id'),
                            ids: modifiers.map(({ id }) => id),
                            currentSort: { field: 'id', order: 'ASC' },
                            setSort: (field, order) => {
                            },
                            selectedIds: [],
                        } }
                    >
                        <Datagrid>
                            <TextField source="id"/>
                            <TextField source="name"/>
                            <TextField source="alias"/>
                            <TextField source="adminOrder"/>
                            <TextField label="Group name" source="parent.name"/>
                            <BooleanField source="hidden"/>
                            <ModifierEditButton productId={ record?.id } parentGroup={ record?.parentGroup } onChange={ handleChange }/>
                        </Datagrid>
                    </ListContextProvider>
                </Tab>
                <Tab label="group modifiers">
                    <ListContextProvider
                        value={ {
                            resource: 'group_modifiers',
                            data: keyBy(groupModifiers, 'id'),
                            ids: groupModifiers.map(({ id }) => id),
                            currentSort: { field: 'id', order: 'ASC' },
                            setSort: (field, order) => {
                            },
                            selectedIds: [],
                        } }
                    >
                        <Datagrid>
                            <TextField source="id"/>
                            <TextField source="name"/>
                            <TextField source="alias"/>
                            <TextField source="adminOrder"/>
                            <BooleanField source="hidden"/>
                            <ModifierEditButton productId={ record?.id } parentGroup={ record?.parentGroup } onChange={ handleChange }/>
                        </Datagrid>
                    </ListContextProvider>
                </Tab>
            </TabbedShowLayout>
        </Show>
    )
};