import * as React from "react";
import { Edit, SimpleForm, TextInput, ReferenceInput, SelectInput, useGetList } from 'react-admin';
import {
    required,
    minLength,
    email,
} from 'react-admin';

export const IntegrationEdit = ({ permissions, ...props }) => {
    return (<Edit { ...props }>
        <SimpleForm>
            <ReferenceInput perPage="200" source="systemIntegrationId" reference="system">
                <SelectInput optionText={(r) => `${r.systemName} ${r.login || r.iikoBizUser}` } />
            </ReferenceInput>
            <ReferenceInput perPage="200" source="messengerIntegrationId" reference="messenger">
                <SelectInput optionText={(r) => `${r.messengerName} ${r.botName}` } />
            </ReferenceInput>
            <ReferenceInput perPage="200" source="userId" reference="user"><SelectInput optionText="email"/></ReferenceInput>
        </SimpleForm>
    </Edit>)
};