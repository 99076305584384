import React, { useState } from 'react';
import { useForm } from 'react-final-form';
import {
  required,
  Button,
  SaveButton,
  TextInput,
  BooleanInput,
  useUpdate,
  useNotify,
  FormWithRedirect, TextField
} from 'react-admin';
import IconContentEdit from '@material-ui/icons/Edit';
import IconCancel from '@material-ui/icons/Cancel';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

export const ModifierEditButton = ({ record, productId, onChange, parentGroup }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [update, { loading, error }] = useUpdate('product', productId);
  const notify = useNotify();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    let modifiers = []
    let groupModifiers = []
    console.log(`parentGroup: ${ JSON.stringify(parentGroup) }`);

    let payload = {
      id: record.id,
      ...values
    }

    if (record.parent) {
      groupModifiers.push({
        id: record.parent.id,
        childModifiers: [payload]
      })
    } else if (record.childModifiers) {
      groupModifiers.push(payload)
    } else {
      modifiers.push(payload)
    }

    update(
      { 
        payload: { 
          data: {
            modifiers,
            groupModifiers,
            parentGroup
          }
        } 
      },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          // Update the comment form to target the newly created post
          // Updating the ReferenceInput value will force it to reload the available posts
          // form.change('post_id', data.id);
          onChange();
        },
        onFailure: ({ error }) => {
          notify(error.message, 'error');
        }
      }
    );
  };

  return (
    <>
      <Button onClick={handleClick} label="ra.action.edit">
        <IconContentEdit />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Edit modifier"
      >
        <DialogTitle>Edit modifier {record?.name || ""}</DialogTitle>

        <FormWithRedirect
          resource="modifier"
          save={handleSubmit}
          record={record}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
              <>
                <DialogContent>
                  <TextInput
                    source="alias"
                    fullWidth
                    helperText={`Этот текст будет отображаться на кнопке, вместо ${record?.name || ""}`}
                    label="Алиас"
                  />

                  <TextInput
                      source="adminOrder"
                      label="Порядок"
                      helperText="Указывать любое число, на основе него будет произведена сортировка при выводе списка модификаторов"
                  />
                  
                  <BooleanInput
                    source="hidden"
                    fullWidth
                    label="Скрыть?"
                    helperText="Если включена, то модификатор будет скрыт из списка"
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    label="ra.action.cancel"
                    onClick={handleCloseClick}
                    disabled={loading}
                  >
                    <IconCancel />
                  </Button>
                  <SaveButton
                    handleSubmitWithRedirect={
                      handleSubmitWithRedirect
                    }
                    pristine={pristine}
                    saving={saving}
                    disabled={loading}
                  />
                </DialogActions>
              </>
            )}
        />
      </Dialog>
    </>
  );
}
