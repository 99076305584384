import {
    ArrayField,
    BooleanField,
    Datagrid,
    DateField,
    NumberField,
    Show,
    TabbedShowLayout,
    TextField,
    useRecordContext,
    RichTextField,
    ReferenceField,
    Tab,
    TopToolbar,
    ListButton
} from "react-admin";
import * as React from "react";

const ShrinkedTextField = (props) => {
    const { source } = props;
    const record = useRecordContext(props);
    let text = record[source];

    if (text && text.length > 6) {
        text = text.replace(/[\n\r]/g, "")
        text = text.substring(0, 7) + '...';
    }

    return <span>{ text }</span>;
}

const TransportOrderActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <ListButton basePath={basePath} />
    </TopToolbar>
);

export const TransportOrderShow = props => (

    <Show actions={<TransportOrderActions />}   { ...props }>
        <TabbedShowLayout>
            <Tab label="Общее">
                <TextField source="id"/>
                <TextField label="id iiko" source="iikoId"/>
                <NumberField label="Номер" source="number"/>
                <TextField label="Тип доставки" source="orderServiceType"/>
                <ReferenceField label="Организация" source="organizationId" reference="organization">
                    <TextField source="adminName" />
                </ReferenceField>
                <TextField label="Телефон" source="phone"/>
                <RichTextField label="Комментарий" source="comment"/>
                <TextField label="Статус" source="status"/>
                <DateField label="Дата создания" source="localCreationTime" showTime/>
                <TextField label="Дата создания(Касса)" source="whenCreated"/>
                <TextField label="Дата отмены(Касса)" source="whenCancelled"/>
                <TextField label="Дата закрытия(Касса)" source="whenClosed"/>
                <BooleanField label="Ожидает отмены" source="toBeCancelled"/>
                <NumberField label="Стоимость" source="sum"/>
                <NumberField label="Доставка" source="deliveryPrice"/>
                <ReferenceField label="Организация" source="organizationId" reference="organization"><TextField source={ "adminName" || "name" }/></ReferenceField>
            </Tab>

            <Tab label="Состав заказа">
                <ArrayField label="Состав заказа" source="items">
                    <Datagrid>
                        <TextField label="Название" source="name"/>
                        <NumberField label="Количество" source="amount"/>
                        <NumberField label="Цена" source="price"/>
                        <TextField label="Причина удаления" source="deleted.deletionMethod.comment"/>
                        <ArrayField label="Модификаторы" source="modifiers">
                            <Datagrid>
                                <NumberField label="Цена" source="price"/>
                                <NumberField label="Количество" source="amount"/>
                                <TextField label="id продукта" source="productId"/>
                            </Datagrid>
                        </ArrayField>
                        <TextField label="id продукта" source="productId"/>
                    </Datagrid>
                </ArrayField>
            </Tab>

            <Tab label="Платежи">
                <ArrayField label="Платежи" source="payments">
                    <Datagrid>
                        <TextField label="Тип оплаты" source="paymentTypeKind"/>
                        <NumberField label="Сумма" source="sum"/>
                        <TextField label="id типа оплаты" source="paymentTypeId"/>
                    </Datagrid>
                </ArrayField>
            </Tab>

            <Tab label="Клиент">
                <TextField label="Имя" source="customer.name"/>
                <TextField label="Фамилия" source="customer.surname"/>
                <TextField label="Телефон" source="phone"/>
            </Tab>

            <Tab label="Адрес доставки">
                <ReferenceField link={ false } label="Улица" source="deliveryPoint.address.street.id" reference="street"><TextField source="name"/></ReferenceField>
                <TextField label="Дом" source="deliveryPoint.address.house"/>
                <TextField label="Квартира" source="deliveryPoint.address.flat"/>
            </Tab>
        </TabbedShowLayout>
    </Show>
);