import * as React from "react";
import { List, Datagrid, TextField, ReferenceField, TextInput, SimpleForm } from 'react-admin';

export const PaymentList = ({ permissions, ...props }) => (
  <List {...props}>
      <Datagrid rowClick="show">
          <TextField source="id" />
          <TextField source="name" />
          <TextField label="username" source="userName"/>
          <TextField source="password"/>
          <TextField source="cryptoKey"/>
          <TextField source="merchant"/>
          {/*{ permissions && <ReferenceField source="userId" reference="user"><TextField source="email" /></ReferenceField> }*/}
      </Datagrid>
  </List>
);