import React, { useState } from 'react';
import {
    BooleanInput,
    Button,
    FormWithRedirect,
    ReferenceField, ReferenceInput,
    SaveButton,
    SelectInput,
    TextField,
    TextInput,
    useGetIdentity,
    useNotify,
    useUpdate,
    usePermissions
} from 'react-admin';
import IconContentEdit from '@material-ui/icons/Edit';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

export const PaymentTypeEditButton = ({ record, organizationId, onChange, ...rest }) => {
    const [showDialog, setShowDialog] = useState(false);
    const [update, { loading, error }] = useUpdate('organization', organizationId);
    const notify = useNotify();

    const { identity } = useGetIdentity();
    const { permissions } = usePermissions();

    const userId = identity?.id;
    console.log(`user id: ${ userId }`);
    record.userId  = userId

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const handleSubmit = async values => {
        let paymentTypes = []

        let payload = {
            id: record.id,
            ...values
        }

        paymentTypes.push(payload)

        update(
            {
                payload: {
                    data: {
                        paymentTypes,
                    }
                }
            },
            {
                onSuccess: ({ data }) => {
                    setShowDialog(false);
                    onChange();
                },
                onFailure: ({ error }) => {
                    notify(error.message, 'error');
                }
            }
        );
    };

    return (
        <>
            <Button onClick={ handleClick } label="ra.action.edit">
                <IconContentEdit/>
            </Button>
            <Dialog
                fullWidth
                open={ showDialog }
                onClose={ handleCloseClick }
                aria-label="Edit payment type"
            >
                <DialogTitle>Edit payment type { record?.name || "" }</DialogTitle>

                <FormWithRedirect
                    resource="paymentType"
                    save={ handleSubmit }
                    record={ record }
                    render={ ({
                                  handleSubmitWithRedirect,
                                  pristine,
                                  saving
                              }) => (
                        <>
                            <DialogContent>
                                <BooleanInput
                                    source="isBonus"
                                    fullWidth
                                    label="Является ли бонусным?"
                                    helperText="Включить, если данный тип оплаты используется для списания бонусов"
                                />
                                <SelectInput
                                    source="showMode"
                                    fullWidth
                                    label="В каком случае использовать?"
                                    helperText="ALWAYS - показывать перед созданием заказа и после, NEVER - не показывать никогда, ONLY_POST_ORDER - показывать только после подтверждения заказа (доступно только для iikoTransport)"
                                    choices={ [
                                        { id: 0, name: 'ALWAYS' },
                                        { id: 1, name: 'NEVER' },
                                        { id: 2, name: 'ONLY_POST_ORDER' },
                                    ] }/>
                                <TextInput
                                    source="alias"
                                    fullWidth
                                    helperText={ `Этот текст будет отображаться на кнопке, вместо ${ record?.name || "" }` }
                                    label="Алиас"
                                />
                                <TextInput
                                    source="order"
                                    label="Порядок"
                                    helperText="Указывать любое число, на основе него будет произведена сортировка при выводе списка доступных типов платежей"
                                />
                                <ReferenceInput allowEmpty={ true } label="payment integration" source="paymentIntegrationId" reference="payment">
                                    {/*<SelectInput fullWidth source="name"/>*/}
                                    <SelectInput helperText="Выбрать, если данный тип оплаты является является внешним, и проводится с помощью эквайринга" fullWidth optionText={ (r) => {
                                        console.log(`option text TRIGGERED`);
                                        console.log(`r: ${ JSON.stringify(r) }`)
                                        const name = r.userName || r.merchant || '';
                                        return `${r.name} ${permissions ? name : ''}`;
                                    } }/>
                                </ReferenceInput>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    label="ra.action.cancel"
                                    onClick={ handleCloseClick }
                                    disabled={ loading }
                                >
                                    <IconCancel/>
                                </Button>
                                <SaveButton
                                    handleSubmitWithRedirect={
                                        handleSubmitWithRedirect
                                    }
                                    pristine={ pristine }
                                    saving={ saving }
                                    disabled={ loading }
                                />
                            </DialogActions>
                        </>
                    ) }
                />
            </Dialog>
        </>
    );
}
