import {
    Show,
    TextField,
    SimpleShowLayout, ReferenceField, SelectInput, ReferenceInput
} from 'react-admin';
import * as React from "react";

export const CategoryShow = props => (
    <Show { ...props }>
        <SimpleShowLayout>
            <TextField source="id"/>
            <TextField source="name"/>
            <ReferenceField source="messengerIntegrationId" reference="messenger">
                <TextField source="messengerName" />
            </ReferenceField>
            <ReferenceField source="userId" reference="user">
                <TextField source="email" />
            </ReferenceField>
        </SimpleShowLayout>
    </Show>
)