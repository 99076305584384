import {
    Create,
    TextInput,
    SimpleForm,
    SelectInput,
    ReferenceInput
} from 'react-admin';
import * as React from "react";

export const CategoryCreate = props => (
    <Create { ...props }>
        <SimpleForm>
            <TextInput source="name"/>
            <ReferenceInput source="messengerIntegrationId" reference="messenger">
                <SelectInput optionText={(r) => `${r.messengerName} ${r.botName}` } />
            </ReferenceInput>
            <ReferenceInput perPage="200" source="userId" reference="user">
                <SelectInput optionText="email"/>
            </ReferenceInput>
        </SimpleForm>
    </Create>
)