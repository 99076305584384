import RichTextInput from 'ra-input-rich-text';

export const RichText = props => {
    return (
        <RichTextInput
            toolbar={[ ['bold', 'italic', 'underline', 'strike', 'link'] ]}
            options={{
                formats: ['bold', 'italic', 'underline', 'strike', 'link']
            }}
            {...props} />
    )
}