import React from 'react';
import { Edit, TextInput, SimpleForm, Toolbar, SaveButton } from 'react-admin';
import {
  required,
  minLength,
  email
} from 'react-admin';

const ProfileEditToolbar = props => (
  <Toolbar {...props} >
      <SaveButton />
  </Toolbar>
);

export const ProfileEdit = ({ staticContext, ...props }) => {
  return (
    <Edit
      {...props}
      id="profile"
      resource="profile"
      basePath="/profile"
      redirect={false} // I don't need any redirection here, there's no list page
      title="My profile"
    >
      <SimpleForm toolbar={<ProfileEditToolbar />}>
        <TextInput source="firstname" />
        <TextInput source="lastname" />
        <TextInput source="email" validate={[email(), required()]} />
        <TextInput source="phone" />
        <TextInput source="organization" />
        <TextInput source="password" validate={minLength(3)} />
      </SimpleForm>
    </Edit>
  );
};