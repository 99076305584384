import * as React from "react";
import { Create, SimpleForm, TextInput, ReferenceInput, SelectInput } from 'react-admin';
import {
  required,
  minLength,
  email,
} from 'react-admin';

export const MessengerCreate = ({ permissions, ...props }) => (
  <Create {...props}>
      <SimpleForm>
          <TextInput source="messengerName" />
          <TextInput source="botToken" />
          <TextInput helperText="Текст, отображаемый до приветствия" label="Имя бота" source="botName" />
          <TextInput helperText="Текст, отображаемый после приветствия" label="Текст приветствия бота" source="botText" />
          <TextInput helperText="Текст, отображаемый по кнопке 'Помощь'" label="Помощь" source="helpText" />
          <ReferenceInput perPage="200" source="userId" reference="user"><SelectInput optionText="email"/></ReferenceInput>
      </SimpleForm>
  </Create>
);