import * as React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  SelectInput,
  TextField,
  ReferenceInput,
  useGetIdentity
} from 'react-admin';
import {
  required,
  minLength,
  email,
} from 'react-admin';

export const UserEdit = props => {
  const canEditAdmin = props.permissions?.can('users.edit.available.admin');
  const canEditHimself = props.permissions?.can('users.edit.self') ;

  const { identity } = useGetIdentity();

  console.log(`user edit props: ${ JSON.stringify(props) }`);

  const isSelfEdit = identity?.id === props.id;

  const canChangeRoleAndCreator = !isSelfEdit || canEditHimself;

  const choices = [{
    id: 'user', name: 'user'
  }, {
    id: 'partner', name: 'partner'
  }];

  if (canEditAdmin) {
    choices.push({
      id: 'admin', name: 'admin'
    })
  }

  return (
      <Edit {...props}>
        <SimpleForm>
          <TextInput source="firstname" />
          <TextInput source="lastname" />
          <TextInput source="email" validate={[email(), required()]} />
          <TextInput source="phone" />
          <TextInput source="organization" />
          { canChangeRoleAndCreator && <ReferenceInput perPage="200" source="creatorId" filter={{ id_ne: props.id, role_ne: 'user' }} reference="user"><SelectInput optionText="email"/></ReferenceInput> }
          { canChangeRoleAndCreator && <SelectInput source="role" choices={choices} /> }
          <TextInput source="password" validate={minLength(3)} />
        </SimpleForm>
      </Edit>
  );
}