import { Datagrid, List, DateField, TextField, FunctionField } from "react-admin";
import * as React from "react";

export const SystemLogList = ({ permissions, ...props }) => {
    return (<List sort={ { field: 'createdAt', order: 'DESC' } } {...props}>
        <Datagrid rowClick="show">
            <TextField source="id" />
            <DateField source="createdAt" showTime />
            <FunctionField
                label="Message"
                render={r => `${r?.message?.slice(0, 100)}`}
            />
        </Datagrid>
    </List>);
}