import { FormDataConsumer, SelectInput, TextInput, required, FileInput, FileField, BooleanInput } from "react-admin";
import * as React from "react";
import Text from "antd/lib/typography/Text";

export const PaymentForm = ({permissions,  ...props}) => {
    return <div {...props}>
        <SelectInput source="name" choices={ [
            { id: 'Sber', name: 'Sber' },
            { id: 'Sber-QR', name: 'Sber-QR' },
            { id: 'Kapital', name: 'Kapital' },
            { id: 'Paykeeper', name: 'Paykeeper' },
            { id: 'Yookassa', name: 'Yookassa' },
            { id: 'Tinkoff', name: 'Tinkoff' },
        ]} { ...props }/>
        <FormDataConsumer { ...props }>
            {
                ({ formData, ...rest }) => {
                    if (formData?.name === 'Sber') {
                        return <div { ...props }>
                            <TextInput validate={[required()]} source="userName"  { ...rest } />
                            <TextInput validate={[required()]} source="password" { ...rest }/>
                            <TextInput source="cryptoKey" />
                            <BooleanInput label="Использовать одностадийную схему" format={(value) => {
                                return typeof value === 'boolean' ? value : value === "true"
                            }} source="serviceAddress" { ...rest }/>
                        </div>
                    } else if (formData?.name === 'Sber-QR') {
                        return <div { ...props }>
                            <TextInput label="Client Id" validate={[required()]} source="userName"  { ...rest } />
                            <TextInput label="Client Secret" validate={[required()]} source="password" { ...rest }/>
                            <TextInput label="Terminal (ID_QR/TID)" validate={[required()]} source="serviceAddress" { ...rest } />
                            <TextInput label="Member ID" validate={[required()]} source="merchant"/>
                            <TextInput label="Certificate Pfx (.p12) in Base64" validate={[required()]} fullWidth multiline={ true } source="crt"/>
                            <TextInput label="Certificate Passphrase" validate={[required()]} source="key"/>
                        </div>
                    }  else if (formData?.name === 'Kapital') {
                        return <div { ...props }>
                            <TextInput validate={[required()]} fullWidth multiline={ true } source="crt"/>
                            <TextInput validate={[required()]} fullWidth multiline={ true } source="key"/>
                            <TextInput source="merchant"/>
                        </div>
                    } else if (formData?.name === 'Yookassa') {
                        return <div { ...props }>
                            <TextInput label="shopId" validate={[required()]} source="userName"  { ...rest } />
                            <TextInput label="API-key" validate={[required()]} source="password" { ...rest } />
                            <Text type="danger">Обязательно! В разделе Уведомления в личном кабинете ЮКасса установить URL для уведомлений "https://api.chat4.biz/pay/yookassa_callback", включить <b>payment.succeeded</b> и <b>payment.waiting_for_capture</b></Text>
                        </div>
                    } else if (formData?.name === 'Tinkoff') {
                        return <div { ...props }>
                            <TextInput label="terminal" validate={[required()]} source="userName"  { ...rest } />
                            <TextInput label="password" validate={[required()]} source="password" { ...rest }/>
                        </div>
                    } else if (formData?.name === 'Paykeeper') {
                        return <div { ...props }>
                            <TextInput label="user name" validate={[required()]} source="userName"  { ...rest } />
                            <TextInput label="password" validate={[required()]} source="password" { ...rest }/>
                            <TextInput label="service address" validate={[required()]} source="serviceAddress" { ...rest }/>
                        </div>
                    }
                }
            }
        </FormDataConsumer>
        <SelectInput
            source="type"
            fullWidth
            label="Режим платежей"
            choices={ [
                { id: 0, name: 'Test' },
                { id: 1, name: 'Prod' },
            ] }/>
    </div>
}