import React, { useState } from 'react';
import { useForm } from 'react-final-form';
import {
    required,
    Button,
    SaveButton,
    TextInput,
    BooleanInput,
    useUpdate,
    useNotify,
    FormWithRedirect, TextField
} from 'react-admin';
import IconContentEdit from '@material-ui/icons/Edit';
import IconCancel from '@material-ui/icons/Cancel';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

export const OrderTypeEditButton = ({ record, organizationId, onChange }) => {
    const [showDialog, setShowDialog] = useState(false);
    const [update, { loading, error }] = useUpdate('organization', organizationId);
    const notify = useNotify();

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const handleSubmit = async values => {
        let orderTypes = []

        let payload = {
            id: record.id,
            ...values
        }

        orderTypes.push(payload)

        update(
            {
                payload: {
                    data: {
                        orderTypes,
                    }
                }
            },
            {
                onSuccess: ({ data }) => {
                    setShowDialog(false);
                    // Update the comment form to target the newly created post
                    // Updating the ReferenceInput value will force it to reload the available posts
                    // form.change('post_id', data.id);
                    onChange();
                },
                onFailure: ({ error }) => {
                    notify(error.message, 'error');
                }
            }
        );
    };

    return (
        <>
            <Button onClick={ handleClick } label="ra.action.edit">
                <IconContentEdit/>
            </Button>
            <Dialog
                fullWidth
                open={ showDialog }
                onClose={ handleCloseClick }
                aria-label="Edit order type"
            >
                <DialogTitle>Edit order type { record?.alias || record?.name || "" }</DialogTitle>

                <FormWithRedirect
                    resource="orderType"
                    save={ handleSubmit }
                    record={ record }
                    render={ ({
                                  handleSubmitWithRedirect,
                                  pristine,
                                  saving
                              }) => (
                        <>
                            <DialogContent>
                                <BooleanInput
                                    source="isHidden"
                                    fullWidth
                                    label="Не показывать"
                                />
                                <TextInput
                                    source="alias"
                                    fullWidth
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    label="ra.action.cancel"
                                    onClick={ handleCloseClick }
                                    disabled={ loading }
                                >
                                    <IconCancel/>
                                </Button>
                                <SaveButton
                                    handleSubmitWithRedirect={
                                        handleSubmitWithRedirect
                                    }
                                    pristine={ pristine }
                                    saving={ saving }
                                    disabled={ loading }
                                />
                            </DialogActions>
                        </>
                    ) }
                />
            </Dialog>
        </>
    );
}
