import * as React from "react";
import { List, Datagrid, TextField, ReferenceField, useGetList } from 'react-admin';

export const IntegrationList = ({ permissions, ...props }) => {
    console.log(`list props: ${ JSON.stringify(props) }`);

    return (
        <List {...props}>
            <Datagrid rowClick="edit">
                <TextField source="id" />
                <ReferenceField source="systemIntegrationId" reference="system"><TextField source="systemName" /></ReferenceField>
                <ReferenceField source="messengerIntegrationId" reference="messenger"><TextField source="messengerName" /></ReferenceField>
                <ReferenceField source="userId" reference="user"><TextField source="email" /></ReferenceField>
            </Datagrid>
        </List>
    );
}