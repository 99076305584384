import * as React from "react";
import {
    Edit,
    SimpleForm,
    TextInput,
    SaveButton,
    Toolbar,
    BooleanInput,
    FormTab,
    ReferenceInput,
    SelectInput, BooleanField, TextField, ReferenceField, ReferenceArrayInput, AutocompleteArrayInput
} from 'react-admin';
import {
    required,
    minLength,
    email,
} from 'react-admin';
import { RichText } from '../base-components/rich-text';

const OrganizationEditToolbar = props => (
    <Toolbar { ...props } >
        <SaveButton/>
    </Toolbar>
);

export const OrganizationEdit = props => (
    <Edit { ...props }>
        <SimpleForm toolbar={ <OrganizationEditToolbar/> }>
            <TextInput label="Телефон" source="phone"/>
            <TextInput source="adminName"/>
            <BooleanInput helperText="Включить, если колл-центр работает только на доставку" label="Прятать адрес" source="hideAddress"/>
            <BooleanInput helperText="Включить, если касса одна и нет смысла выбирать" label="Пропускать выбор кассы" fullWidth source="autoSelectTerminals"/>
            <TextInput fullWidth source="adminRestaurantAddress" label="Restaurant address"/>
            <RichText helperText="Текст, отображаемый вместо ожидаемого времени доставки" label="Комментарий к доставке" source="deliveryComment" />
            <ReferenceInput allowEmpty source='geoIntegrationId' reference="geo">
                <SelectInput helperText="Использовать настройки гео-локации для данной организации" optionText="name"/>
            </ReferenceInput>
            <BooleanInput helperText="Временно скрыть организацию из списка" label="Скрывать огранизацию" source="isHidden" />
            <BooleanInput helperText="Показывать комментарий пользователя в уведомлениях о заказе" label="Показывать комментарий" source="showComment" />
            <ReferenceArrayInput source="categories" reference="category">
                <AutocompleteArrayInput allowNull helperText="Привязывает организацию к категориям. Полезно для разбивки по городам." optionText="name"/>
            </ReferenceArrayInput>
            <ReferenceInput label="Зеркало" allowEmpty reference="organization" source='mirrorOrganizationId'>
                <SelectInput optionText="adminName"/>
            </ReferenceInput>
        </SimpleForm>
    </Edit>
);