import simpleRestProvider from 'ra-data-simple-rest';
import { getIdentity, setIdentity } from './helpers/identity'
import { httpClient } from './helpers/client'
import * as url from "url";

const orderProvider = simpleRestProvider(process.env.REACT_APP_ORDERS_URL, httpClient)
const organizationProvider = simpleRestProvider(process.env.REACT_APP_ORGANIZATIONS_URL, httpClient)
const integrationProvider = simpleRestProvider(process.env.REACT_APP_INTEGRATIONS_URL, httpClient)

const notificationProvider = simpleRestProvider(process.env.REACT_APP_NOTIFICATIONS_URL, httpClient)

const accountServiceUrl = process.env.REACT_APP_ACCOUNT_URL
const accountProvider = simpleRestProvider(accountServiceUrl, httpClient)

const getProvider = (resource) => {
  console.log(`data provider resource: ${ resource }`);
  switch (resource) {
    case 'user':
      return accountProvider
    case 'system':
    case 'messenger':
    case 'integration':
    case 'payment':
    case 'geo':
    case 'logs':
      return integrationProvider
    case 'organization':
    case 'product':
    case 'deliveryRestriction':
    case 'group':
    case 'street':
    case 'city':
    case 'category':
      return organizationProvider
    case 'transport':
    case 'biz':
      return orderProvider
    case 'audience':
    case 'trigger':
    case 'log':
    case 'messenger-user':
      return notificationProvider
  }
}

// const orgTransform = (orgId, source, keys) => {
//   const transform = (e) => {
//     for (let key of keys) {
//       if (e[key]) {
//         e[key] = `${orgId}/${e[key]}`
//       }
//     }
//   }

//   if (Array.isArray(source)) {
//     return source.map(transform)
//   }

//   return transform(source)
// }

export default {
  getList: (resource, params) => {
    return getProvider(resource).getList(resource, params)
  },

  getMany: (resource, params) => {
    return getProvider(resource).getMany(resource, params)
  },

  getManyReference: (resource, params) => {
    // let result = getProvider(resource).getManyReference(resource, params)

    // if (resource === 'group') {
    //   return result.then((res) => {
    //     orgTransform(params.id, res.data, ['id', 'parentGroup'])

    //     return res
    //   })
    // }

    return getProvider(resource).getManyReference(resource, params)
  },

  updateMany: (resource, params) => {
    return getProvider(resource).updateMany(resource, params)
  },

  create: (resource, params) => {
    return getProvider(resource).create(resource, params)
  },

  delete: (resource, params) => {
    return getProvider(resource).delete(resource, params)
  },

  deleteMany: (resource, params) => {
    return getProvider(resource).deleteMany(resource, params)
  },

  getOne: (resource, params, options) => {
    console.log(`resource: ${JSON.stringify(resource)}`);
    console.log(`params: ${JSON.stringify(params)}`);
    console.log(`location: ${JSON.stringify(window.location)}`);

    modifyParams(resource, params);

    if (resource === 'profile') {
      return getIdentity()
        .then((user) => {
          user.id = 'profile'
          return { data: user }
        })
    }

    return getProvider(resource).getOne(resource, params)
  },
  update: (resource, params) => {
    if (resource === 'profile') {
      return setIdentity(params.data)
        .then((user) => {
          user.id = 'profile'
          return { data: user }
        })
    }

    modifyParams(resource, params)

    return getProvider(resource).update(resource, params)
  }
};

// TODO: Refactor this functions

function modifyParams(resource, params) {
  switch(resource) {
    case 'product':
      modifyParamsOrganizationWithGroup(params)
      break;
    case 'group':
      modifyParamsOrganization(params)
      break;
    case 'street':
      modifyParamsOrganization(params)
      break;
    case 'city':
      modifyParamsOrganization(params)
      break;
    case 'deliveryRestriction':
      modifyParamsOrganization(params)
      break;
    default:
      console.log('NOT FOUND')
      break;
  }

  console.log('params', resource, params)
}

function modifyParamsOrganizationNested(params) {
  const href = window.location.href;
  const urlArray = href.split('/');
  const idIndex = urlArray.indexOf(params.id);
  const organizationId = urlArray[idIndex - 1];

  params.id = `${ organizationId }/${ params.id }`
}

function modifyParamsOrganization(params) {
  const organizationId = params.organizationId || params.data?.organizationId;
  if (organizationId) {
    params.id = `${ organizationId }/${ params.id }`
  } else {
    modifyParamsOrganizationNested(params)
  }
}

function getOrganizationAndGroupIdFromParams(params) {
  const href = window.location.href;
  const urlArray = href.split('/');
  const idIndex = urlArray.indexOf(params.id);
  const organizationId = urlArray?.[idIndex - 2];
  const parentGroupId = urlArray?.[idIndex - 1];

  return { organizationId, parentGroupId };
}

function modifyParamsOrganizationWithGroup(params) {
  const { parentGroupId: parentGroupIdFromParams, organizationId: organizationIdFromParams } = getOrganizationAndGroupIdFromParams(params);

  const organizationId = params.organizationId || params.data?.organizationId || organizationIdFromParams;
  const parentGroup = params.parentGroup || params.data?.parentGroup || parentGroupIdFromParams;

  params.id = `${ organizationId }/${ parentGroup }/${ params.id }`
}