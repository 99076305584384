import { ReferenceField, DateField, FunctionField, SimpleShowLayout, ListButton, SelectField, Show, TextField, TopToolbar } from "react-admin";
import * as React from "react";

const SystemLogActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <ListButton basePath={basePath} />
    </TopToolbar>
);

export const SystemLogShow = props => {

    return (<Show actions={<SystemLogActions />}  {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <DateField source="createdAt" showTime />
            <ReferenceField source="organizationId" reference="organization">
                <TextField source="adminName" />
            </ReferenceField>
            <ReferenceField source="systemIntegrationId" reference="system">
                <FunctionField
                    label="System integration"
                    render={(r) => `${r.systemName} ${r.login || r.iikoBizUser}`}
                />
            </ReferenceField>
            <ReferenceField source="messengerIntegrationId" reference="messenger">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="userId" reference="user">
                <FunctionField
                    label="User"
                    render={(r) => `${r.firstName || ''} ${r.lastName || ''}`}
                />
            </ReferenceField>
            <TextField source="message"/>
        </SimpleShowLayout>
    </Show>)
}