
import React, { useState } from 'react';
import SyncIcon from '@material-ui/icons/Sync';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import { httpClient } from '../providers/helpers/client'
import {
  Button,
  showNotification
} from 'react-admin';

const _SynchronizeButton = ({ record, showNotification }) => {
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setLoading(true)
    httpClient(`${process.env.REACT_APP_ORGANIZATIONS_URL}/organization/${record.id}/update_nomenclature`, { method: 'POST' })
      .then(resp => {
        if (!resp?.json) {
          throw new Error(resp.statusText)
        }

        if (!resp.json.success) {
          showNotification(`Warning: organization synchronized, but with warnings. Check system logs for details.`)
        } else {
          showNotification('Success: organization and its nomenclature synchronized');
        }
      })
      .catch((e) => {
        showNotification(`Error: could not synchronize organization ${e}`, 'error')
      })
      .finally(() => {
        setLoading(false)
      });
  }

  return (
    <Button 
      onClick={handleClick} 
      label="synchronize" 
      disabled={loading}>
      { loading ? <CircularProgress size={20} /> : <SyncIcon /> }
    </Button>
  )
}

export const SynchronizeButton = connect(null, { showNotification })(_SynchronizeButton)