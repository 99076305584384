import * as React from "react";
import { List, Datagrid, TextField, ReferenceField } from 'react-admin';

export const MessengerList = ({ permissions, ...props }) => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField label="Messenger" source="messengerName" />
            <TextField source="botName" />
            <ReferenceField source="userId" reference="user"><TextField source="email" /></ReferenceField>
        </Datagrid>
    </List>
);