import * as React from "react";
import {
    SelectField,
    Show,
    TabbedShowLayout,
    TextField,
    Tab,
    ReferenceManyField,
    Datagrid,
    Pagination,
    ShowButton,
    ListButton,
    TopToolbar,
    EditButton,
    ListContextProvider,
    BooleanField,
    useShowController,
    useRecordContext,
    SelectInput,
    FormTab,
    BooleanInput,
    ReferenceInput, ReferenceArrayField, ChipField, SingleFieldList
} from 'react-admin';
import { keyBy } from "lodash"
import { SynchronizeButton } from "./sync"
import { TerminalGroupEditButton } from "./terminal-groups/edit";
import { GroupEditButton } from "./groups/edit"
import { CityEditButton } from "./cities/edit"
import { PaymentTypeEditButton } from "./payment-types/edit";
import { useCallback, useState } from "react";
import { OrderTypeEditButton } from "./order-types/edit";
import { DeliveryRestrictionCreateButton, DeliveryRestrictionEditButton } from "./delivery-restrictions/edit";
import { ReferenceField } from "ra-ui-materialui";

const GroupShowButton = ({ record, ...props }) => (
    <ShowButton to={`/group/${props.organizationId}/${record.id}/show`} />
)


const OrganizationActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <SynchronizeButton record={data} />
        <ListButton basePath={basePath} />
        <EditButton basePath={basePath} record={data} />
    </TopToolbar>
);

const ShowModeField = (props) => {
    console.log(`props: ${JSON.stringify(props)}`);
    const record = useRecordContext(props);
    console.log(`record: ${JSON.stringify(record)}`);
    const modes = {
        0: 'ALWAYS',
        1: 'NEVER',
        2: 'ONLY_POST_ORDER'
    }
    return (
        <span>
            {modes[record.showMode]}
        </span>
    );
}

export const OrganizationShow = props => {

    const [version, setVersion] = useState(0);
    const handleChange = useCallback(() => setVersion(version + 1), [version]);

    const { record } = useShowController(props)

    let terminalGroups = record?.terminalGroups || [];
    let orderTypes = record?.orderTypes || [];
    let paymentTypes = record?.paymentTypes || [];

    // if (record) {
    //     record.organizationId = record.id;
    //     console.log(`org record: ${ JSON.stringify(record) }`);
    // }

    console.log(`SHOW props: ${JSON.stringify(props)}`)

    return (

        <Show actions={<OrganizationActions />} {...props}>
            <TabbedShowLayout>
                <Tab label="Общее">
                    <TextField source="id" />
                    <TextField source="adminName" />
                    <TextField source="adminRestaurantAddress" label="Restaurant address" />
                    <TextField source="phone" />
                    <BooleanField source="showComment" />
                    <BooleanField source="isHidden" />
                    <TextField source="deliveryComment" label="Delivery comment" />
                    <ReferenceField reference="organization" source="mirrorOrganizationId">
                        <TextField source="adminName" />
                    </ReferenceField>
                    {/* <ReferenceField reference="geo" source="geoIntegrationId">
                        <TextField source="name" />
                    </ReferenceField> */}
                    <ReferenceArrayField source="categories" reference="category">
                        <SingleFieldList>
                            <ChipField source="name" />
                        </SingleFieldList>
                    </ReferenceArrayField>
                </Tab>
                <Tab label="Номенклатура">
                    <ReferenceManyField
                        addLabel={false}
                        reference="group"
                        target="organizationId"
                        filter={{
                            isGroupModifier: false,
                            isDeleted: false
                        }}
                        pagination={<Pagination />}
                    >
                        <Datagrid>
                            <TextField source="name" />
                            <TextField source="showMode"/>
                            <ReferenceField source="parentGroup" reference="group">
                                <TextField source="name"/>
                            </ReferenceField>
                            <GroupShowButton organizationId={props.id} />
                            <GroupEditButton organizationId={record?.id} onChange={handleChange} />
                        </Datagrid>
                    </ReferenceManyField>
                </Tab>
                <Tab label="Ограничения доставки">
                    <DeliveryRestrictionCreateButton organizationId={props.id} />
                    <ReferenceManyField
                        addLabel={false}
                        reference="deliveryRestriction"
                        target="organizationId"
                    >
                        <Datagrid>
                            <SelectField fullWidth source="deliveryCalculationMethod" choices={[
                                { id: 0, name: 'По сумме заказа' },
                                { id: 1, name: 'Как в настройках картографии' },
                                { id: 2, name: 'По зонам' },
                            ]} />
                            <DeliveryRestrictionEditButton organizationId={props.id} />
                        </Datagrid>
                    </ReferenceManyField>
                </Tab>
                <Tab label="Кассы">
                    <ListContextProvider
                        value={{
                            resource: 'terminalGroups',
                            data: keyBy(terminalGroups, 'id'),
                            ids: terminalGroups.map(({ id }) => id),
                            currentSort: { field: 'id', order: 'ASC' },
                            setSort: (field, order) => {
                            },
                            selectedIds: [],
                        }}
                    >
                        <Datagrid>
                            <TextField source="id" />
                            <TextField source="name" />
                            <TextField source="address" />
                            <BooleanField source="isAlive" />
                            <BooleanField source="notForSelfService" />
                            <BooleanField source="notForDelivery" />
                            <BooleanField source="preferred" />
                            <TerminalGroupEditButton organizationId={record?.id} onChange={handleChange} />
                        </Datagrid>
                    </ListContextProvider>
                </Tab>
                <Tab label="Типы заказов">
                    <ListContextProvider
                        value={{
                            resource: 'orderTypes',
                            data: keyBy(orderTypes, 'id'),
                            ids: orderTypes.map(({ id }) => id),
                            currentSort: { field: 'id', order: 'ASC' },
                            setSort: (field, order) => {
                            },
                            selectedIds: [],
                        }}
                    >
                        <Datagrid>
                            <TextField source="id" />
                            <TextField source="name" />
                            <TextField source="alias" />
                            <TextField source="orderServiceType" />
                            <BooleanField source="isHidden" />
                            <OrderTypeEditButton organizationId={record?.id} onChange={handleChange} />
                        </Datagrid>
                    </ListContextProvider>
                </Tab>
                <Tab label="Типы оплат">
                    <ListContextProvider
                        value={{
                            resource: 'paymentTypes',
                            data: keyBy(paymentTypes, 'id'),
                            ids: paymentTypes.map(({ id }) => id),
                            currentSort: { field: 'id', order: 'ASC' },
                            setSort: (field, order) => {
                            },
                            selectedIds: [],
                        }}
                    >
                        <Datagrid>
                            <TextField source="id" />
                            <TextField source="name" />
                            <TextField source="comment" />
                            <TextField source="order" />
                            <BooleanField source="isBonus" />

                            <ShowModeField source="showMode" />

                            <TextField source="alias" />
                            <PaymentTypeEditButton organizationId={record?.id} onChange={handleChange} />
                        </Datagrid>
                    </ListContextProvider>
                </Tab>
                <Tab label="Города">
                        <ReferenceManyField
                            addLabel={false}
                            reference="city"
                            target="organizationId"
                            filter={{
                                isDeleted: false,                                
                            }}
                            sort={{ field: 'name', order: 'ASC' }}
                            pagination={<Pagination />}
                        >
                            <Datagrid>
                                <TextField source="name" />
                                <BooleanField source="hidden" />
                                <BooleanField source="forceLoadStreets" />
                                <CityEditButton organizationId={record?.id} onChange={handleChange} />
                            </Datagrid>
                        </ReferenceManyField>
                    </Tab>
            </TabbedShowLayout>
        </Show>
    )
};