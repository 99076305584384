import {
    FormDataConsumer,
    SelectInput,
    TextInput,
    FileInput,
    FileField,
    useInput, ArrayInput, SimpleFormIterator, NumberInput
} from "react-admin";
import * as React from "react";
import { useState } from "react";

export const DeliveryPricingForm = ({ permissions, ...props }) => {
    if (props.record) {
        props.record.organizationId = props.organizationId;
    }

    const [json, setJson] = useState({ original: props.record?.geoJSON, current: props.record?.geoJSON });


    function validateGeoJSON(r) {
        console.log(`r.type: ${ r.type }`);
        if (r.type === 'FeatureCollection') {
            if (r.features?.length) {
                return r;
            }
        }
        if (r.type === 'Feature') {
            return {
                type: 'FeatureCollection',
                features: [r]
            }
        }

        return undefined;
    }

    const fileAndTexts = (
        <>
        <FileInput removeButton onChange={ (arg) => {
            console.log(`on change called`);
            console.log(`arg: ${ JSON.stringify(arg) }`);

            // delete formData.geoJSON;
            if (arg) {
                setJson({ ...json, current: undefined });
            } else {
                // console.log(`set json on change: ${ props.record?.geoJSON }`)
                // delete formData.geoJSON;
                setJson({ ...json, current: json.original });
            }
        }} source="file" label="geojson">
            <FileField download={false} source="geoData" title="geojson"/>
        </FileInput>
            <label> Без определения зоны </label>
        <NumberInput initialValue={null} label="Стоимость доставки" source="outOfZonesPricing.deliveryPrice"/>
        <NumberInput initialValue={null} label="Минимальная сумма заказа" source="outOfZonesPricing.minOrderPrice"/>
        <NumberInput initialValue={null} label="Бесплатная доставка от" source="outOfZonesPricing.freeDeliveryPrice"/>
        </>
    );

    return <div { ...props }>
        <SelectInput fullWidth source="deliveryCalculationMethod" choices={ [
            { id: 0, name: 'По сумме заказа' },
            { id: 1, name: 'Как в настройках картографии' },
            { id: 2, name: 'По зонам' },
        ] } { ...props }/>
        <FormDataConsumer subscription={{ geoJSON: true, values: true }}  { ...props }>
            {
                ({ formData, ...rest }) => {
                    const inputFileUrl = formData?.file?.geoData;
                    console.log(`FORM RENDER START`);
                    // console.log(`props geojson: ${ JSON.stringify(props?.record?.geoJSON) }`);
                    // if (!formData.geoJSON && !inputFileUrl) {
                    //
                    // }

                    if ((formData.geoJSON && !json?.current) || (!formData.geoJSON && json?.current)) {
                        formData.geoJSON = json?.current;
                    }

                    // formData.geoJSON = json?.current || json?.original;

                    console.log(`formData: ${ JSON.stringify(formData) }`);
                    console.log(`rest: ${ JSON.stringify(rest) }`);

                    // console.log(`inputFileUrl: ${ JSON.stringify(inputFileUrl) }`);
                    if (formData?.deliveryCalculationMethod === 0) {
                        return <div { ...props }>
                            <TextInput fullWidth label="Минимальная сумма заказа" helperText="для бесплатной доставки"
                                       source="minOrderPrice"/>
                            <TextInput fullWidth label="Стоимость доставки" source="deliveryPrice"/>
                        </div>
                    } else if (formData?.deliveryCalculationMethod === 2) {

                        if (!json?.current) {
                            if (inputFileUrl) {
                                fetch(inputFileUrl)
                                    .then((response) => response.json())
                                    .then(r => {
                                        // formData.geoJSON = r;
                                        const validatedResult = validateGeoJSON(r);
                                        console.log(`validation result: ${ Boolean(validatedResult) }`)
                                        setJson({ ...json, current: validatedResult });
                                    });
                            }
                            // const a = { input: { onChange } } = useInput(props);
                            return <div { ...rest }>
                                { fileAndTexts }
                            </div>
                        } else {
                            // console.log(`props record: ${ JSON.stringify(props.record) }`);
                            // console.log(`form data: ${ JSON.stringify(formData) }`);
                            return <div { ...rest }>
                                { fileAndTexts }
                                <ArrayInput { ...rest } label="С определением зоны" source="geoJSON.features" >
                                    <SimpleFormIterator disableAdd disableRemove>
                                        <TextInput disabled={true} source="properties.description" label="Название зоны"/>
                                        <NumberInput initialValue={null} source="properties.deliveryPrice" label="Стоимость доставки"/>
                                        <NumberInput initialValue={null} source="properties.minOrderPrice" label="Минимальная сумма заказа"/>
                                        <NumberInput initialValue={null} source="properties.freeDeliveryPrice" label="Бесплатная доставка от"/>
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </div>
                        }
                    }
                }
            }
        </FormDataConsumer>
    </div>
}