import * as React from "react";
import { List, Datagrid, TextField, ReferenceField, TextInput, SimpleForm } from 'react-admin';

export const SystemList = ({ permissions, ...props }) => {
    // console.log(`list props: ${ JSON.stringify(props) }`);

    return (
        <List {...props}>
            <Datagrid rowClick="show">
                <TextField source="id" />
                <TextField source="systemName" />

                <TextField label="Transport login"  source="login" />
                <TextField label="Biz user" source="iikoBizUser" />

                <ReferenceField source="userId" reference="user"><TextField source="email" /></ReferenceField>
            </Datagrid>
        </List>
    );
}