import * as React from "react";
import {
    Edit, EditButton, ListButton, ReferenceField, SaveButton, ShowButton,
    SimpleForm, TextField, Toolbar, TopToolbar, useRecordContext, Create, CreateButton
} from 'react-admin';
import { DeliveryPricingForm } from "./delivery-calculation";

export const DeliveryRestrictionEditButton = ({ record, ...props }) => (
    <EditButton to={ `/deliveryRestriction/${ props.organizationId }/${ record.id }/edit` }/>
)

export const DeliveryRestrictionCreateButton = ({ record, ...props }) => (
    <CreateButton to={ `/deliveryRestriction/${ props.organizationId }/${ record.id }/create` }/>
)

const DeliveryRestrictionActions = ({ deliveryRestrictionId, organizationId, data, ...props }) => {
    if (!organizationId) {
        return (<></>);
    }

    return (
        <TopToolbar>
            <ShowButton to={ `/organization/${ organizationId }/show/2` } label="Organization"/>
        </TopToolbar>
    )
};

const DeliveryRestrictionToolBar = (props) => {
    return (<Toolbar { ...props }>
        <SaveButton/>
    </Toolbar>)
}

export const DeliveryRestrictionEdit = ({ permissions, ...props }) => {
    props.id = props?.match?.params?.id;
    props.organizationId = props?.match?.params?.organizationId;
    console.log(`delivery props: ${ JSON.stringify(props) }`);
    return (
        <Edit actions={ <DeliveryRestrictionActions { ...props }/> } { ...props }>
            <SimpleForm { ...props } toolbar={ <DeliveryRestrictionToolBar/> }>
                <DeliveryPricingForm { ...props }/>
            </SimpleForm>
        </Edit>
    )
}

export const DeliveryRestrictionCreate = ({ permissions, ...props }) => {
    props.id = props?.match?.params?.id;
    props.organizationId = props?.match?.params?.organizationId;
    console.log(`delivery props: ${ JSON.stringify(props) }`);

    return (
        <Create actions={ <DeliveryRestrictionActions { ...props }/> } { ...props }>
            <SimpleForm { ...props }
                        toolbar={ <DeliveryRestrictionToolBar/> }>
                <label> В данный момент поддерживается только один режим ограничения доставки </label>
                <DeliveryPricingForm { ...props }/>
            </SimpleForm>
        </Create>
    )
}


