import * as React from "react";
import { List, Datagrid, TextField, EmailField, BooleanField, ReferenceField } from 'react-admin';

export const UserList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="firstname" />
      <TextField source="lastname" />
      <EmailField source="email" />
      <TextField source="phone" />
      <TextField source="organization" />
      <ReferenceField source="creatorId" reference="user"><TextField source="email" /></ReferenceField>
      <TextField source="role" />
    </Datagrid>
  </List>
);