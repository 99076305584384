import {
    RichTextField,
    ArrayField,
    BooleanField,
    Datagrid,
    DateField,
    NumberField,
    ReferenceField,
    Show,
    TabbedShowLayout,
    Tab,
    TextField,
    TopToolbar,
    ListButton,
    FunctionField
} from "react-admin";
import * as React from "react";


const BizOrderActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <ListButton basePath={basePath} />
    </TopToolbar>
);

export const BizOrderShow = props => (

    <Show  actions={<BizOrderActions />}  { ...props }>
        <TabbedShowLayout>
            <Tab label="Общее">
                <TextField source="id"/>
                <TextField label="id iiko" source="bizId"/>
                <TextField label="Номер" source="number"/>
                <BooleanField label="Самовывоз?" source="isSelfService"/>
                <ReferenceField label="Организация" source="organizationId" reference="organization">
                    <TextField source="adminName" />
                </ReferenceField>
                <TextField label="Статус" source="status"/>
                <FunctionField label="Адрес доставки" render={ r => {
                    if (r.address) {
                        return `${r.address.city}, ${r.address.street}, ${r.address.home}, ${r.address.apartment}`
                    } else {
                        return 'Unspecified'
                    }
                }} />
                <DateField label="Время создания" source="date" showTime/>
                <TextField label="Телефон" source="phone"/>
                <RichTextField label="Комментарий" source="comment"/>
                <DateField label="Локальное время создания" source="localCreationTime" showTime/>
                <DateField label="Время отмены" source="cancelTime" showTime/>
                <DateField label="Время закрытия" source="closeTime" showTime/>
                <BooleanField label="Ожидает отмены" source="toBeCancelled"/>
                <NumberField label="Стоимость заказа" source="sum"/>
                <NumberField label="Стоимость доставки" source="deliveryPrice"/>
                <ReferenceField label="Организация" source="organizationId" reference="organization"><TextField source={ "adminName" || "name" }/></ReferenceField>
            </Tab>

            <Tab label="Состав заказа">
                <ArrayField label="Состав заказа" source="items">
                    <Datagrid>
                        <TextField label="Название" source="name"/>
                        <NumberField label="Количество" source="amount"/>
                        <NumberField label="Стоимость" source="sum"/>
                        <ArrayField label="Модификаторы" source="modifiers">
                            <Datagrid>
                                <TextField label="Название" source="name"/>
                                <NumberField label="Количество" source="amount"/>
                                <NumberField label="Стоимость" source="sum"/>
                                {/*<TextField source="id" />*/ }
                            </Datagrid>
                        </ArrayField>
                        <TextField label="id продукта" source="productId"/>
                    </Datagrid>
                </ArrayField>
            </Tab>

            <Tab label="Платежи">
                <ArrayField label="Платежи" source="payments">
                    <Datagrid>
                        <TextField label="Тип оплаты" source="paymentType.name"/>
                        <NumberField label="Сумма" source="sum"/>
                        <TextField label="id типа оплаты" source="paymentType.id"/>
                    </Datagrid>
                </ArrayField>
            </Tab>

            <Tab label="Клиент">
                <TextField label="Имя" source="customer.name"/>
                <TextField label="Фамилия" source="customer.surName"/>
                <TextField label="Телефон" source="customer.phone"/>
            </Tab>

            <Tab label="Адрес доставки">
                <TextField label="Улица" source="address.street"/>
                <TextField label="Дом" source="address.home"/>
                <TextField label="Квартира" source="address.apartment"/>
            </Tab>

        </TabbedShowLayout>
    </Show>
);