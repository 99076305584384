import * as React from "react";
import { Admin, ListGuesser, Resource, ShowGuesser, EditGuesser } from 'react-admin';
import { Route } from 'react-router-dom';
import authProvider from './providers/authProvider';
import { UserList } from './users/list';
import { UserEdit } from './users/edit';
import { UserCreate } from './users/create';
import { ProfileEdit } from './profile/edit'
import { MyLayout } from './profile/userMenu'
import dataProvider from './providers/dataProvider'
import { SystemList } from './integrations/system/list'
import { SystemEdit } from './integrations/system/edit'
import { SystemCreate } from './integrations/system/create'
import { IntegrationList } from './integrations/list'
import { IntegrationEdit } from './integrations/edit'
import { IntegrationCreate } from './integrations/create'
import { MessengerList } from './integrations/messenger/list'
import { MessengerEdit } from './integrations/messenger/edit'
import { MessengerCreate } from './integrations/messenger/create'
import { OrganizationList } from './organizations/list'
import { OrganizationEdit } from './organizations/edit'
import { OrganizationShow } from './organizations/show'
import { GroupShow } from './organizations/groups/show'
import { ProductShow } from './organizations/products/show'
import UserIcon from '@material-ui/icons/People';
import TelegramIcon from '@material-ui/icons/Telegram';
import BusinessIcon from '@material-ui/icons/Business';
import LinkIcon from '@material-ui/icons/Link';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { SystemShow } from "./integrations/system/show";
import { PaymentList } from "./integrations/payment/list";
import { PaymentEdit } from "./integrations/payment/edit";
import { PaymentShow } from "./integrations/payment/show";
import { PaymentCreate } from "./integrations/payment/create";
import { DeliveryRestrictionCreate, DeliveryRestrictionEdit } from "./organizations/delivery-restrictions/edit";
import polyglotI18nProvider from 'ra-i18n-polyglot';
import defaultMessages from 'ra-language-english';
import { TransportOrderList } from "./order/transport/list";
import { BizOrderList } from "./order/biz/list";
import { BizOrderShow } from "./order/biz/show";
import { TransportOrderShow } from "./order/transport/show";
import { ListAltOutlined } from "@material-ui/icons";
import { OfferLogList } from "./notification/log/list";
import { AudienceList } from "./notification/audience/list";
import { TriggerList } from "./notification/trigger/list";
import { TriggerCreate } from "./notification/trigger/create";
import { AudienceEdit } from "./notification/audience/edit";
import { TriggerShow } from "./notification/trigger/show";
import { TriggerEdit } from "./notification/trigger/edit";
import { AudienceCreate } from "./notification/audience/create";
import { MessengerUserList } from "./notification/user/list";
import { MessengerUserShow } from "./notification/user/show";
import { AudienceShow } from "./notification/audience/show";
import { GeoList } from "./integrations/geo/list";
import { GeoCreate } from "./integrations/geo/create";
import { GeoEdit } from "./integrations/geo/edit";
import NavigationIcon from '@material-ui/icons/Navigation';
import PeopleIcon from '@material-ui/icons/People';
import EventIcon from '@material-ui/icons/Event';
import Person from '@material-ui/icons/Person';
import HistoryIcon from '@material-ui/icons/History';
import { CategoryShow } from "./categories/show";
import { CategoryEdit } from "./categories/edit";
import { CategoryCreate } from "./categories/create";
import { CategoryList } from "./categories/list";
import { SystemLogList } from "./integrations/logs/list";
import { SystemLogShow } from "./integrations/logs/show";

const i18nProvider = polyglotI18nProvider(() => defaultMessages, "en", { allowMissing: true, onMissingKey: (key, _, __) => key });

const App = () => <Admin
    disableTelemetry
    authProvider={authProvider}
    dataProvider={dataProvider}
    i18nProvider={i18nProvider}
    customRoutes={[
        <Route
            key="profile"
            path="/profile"
            component={ProfileEdit}
        />,
        <Route path="/group/:organizationId/:id/show" render={(props) => {
            console.log(`GROUP props: ${JSON.stringify(props)}`);
            return (
                <GroupShow resource="group" {...props} />
            )
        }} />,
        <Route path="/product/:organizationId/:parentGroup/:id/show" render={(props) => (
            <ProductShow resource="product" {...props} />
        )} />,
        <Route path="/deliveryRestriction/:organizationId/:id/edit" render={(props) => (
            <DeliveryRestrictionEdit basePath={`/organization/${props?.match?.params?.organizationId}/show/2`} resource="deliveryRestriction" {...props} />
        )} />,
        <Route path="/deliveryRestriction/:organizationId/:id/create" render={(props) => (
            <DeliveryRestrictionCreate basePath={`/organization/${props?.match?.params?.organizationId}/show/2`} resource="deliveryRestriction" {...props} />
        )} />,

    ]}
    layout={MyLayout}
>
    {permissions => {
        const AdminOnly = (resource) => {
            return permissions === 'admin' ? resource : null;
        }

        const AdminAndPartnerOnly = (resource) => {
            return permissions === 'admin' || permissions === 'partner' ? resource : null;
        }

        const checkPermissions = (resource, ...varArg) => {
            return permissions && permissions.can(...varArg) ? resource : null;
        }

        // const groupMatch = {
        //   id:
        // }

        return [
            <Resource name="profile" />,
            <Resource name="product" />,
            <Resource name="group" />,
            <Resource name="deliveryRestriction"
            // edit={ DeliveryRestrictionEdit }
            />,
            <Resource name="user"
                list={checkPermissions(UserList, 'users', 'list')}
                edit={checkPermissions(UserEdit, 'users', 'edit', 'general')}
                create={checkPermissions(UserCreate, 'users', 'create', 'general')}
                icon={UserIcon} />,
            <Resource name="system"
                list={SystemList}
                edit={SystemEdit}
                show={SystemShow}
                create={SystemCreate} />,
            <Resource name="messenger"
                list={MessengerList}
                edit={MessengerEdit}
                create={MessengerCreate}
                icon={TelegramIcon} />,
            <Resource name="integration"
                list={IntegrationList}
                edit={IntegrationEdit}
                create={IntegrationCreate}
                icon={LinkIcon} />,
            <Resource name="payment"
                list={PaymentList}
                edit={PaymentEdit}
                show={PaymentShow}
                create={PaymentCreate}
                icon={CreditCardIcon}
            />,
            <Resource name='geo'
                options={{ label: 'Cartography' }}
                list={GeoList}
                create={GeoCreate}
                edit={GeoEdit}
                icon={NavigationIcon}
            />,
            <Resource name="organization"
                list={OrganizationList}
                show={OrganizationShow}
                edit={OrganizationEdit}
                icon={BusinessIcon} />,
            <Resource name="transport"
                options={{ label: 'iiko.transport orders' }}
                list={TransportOrderList}
                show={TransportOrderShow}
                icon={ListAltOutlined}
            />,
            <Resource name="biz"
                options={{ label: 'iiko.biz orders' }}
                list={BizOrderList}
                show={BizOrderShow}
                icon={ListAltOutlined}
            />,
            <Resource name="street" />,
            <Resource name="city" />,
            <Resource name="category"
                list={CategoryList}
                show={CategoryShow}
                create={CategoryCreate}
                edit={CategoryEdit}
                icon={ListAltOutlined}
            />,
            <Resource name="logs"
                options={{ label: 'System logs' }}
                list={SystemLogList}
                show={SystemLogShow}
                icon={HistoryIcon}
            />,
            // <Resource name="log"
            //           options={{ label: 'Notification logs' }}
            //           list={ OfferLogList }
            //           icon={ HistoryIcon }
            // />,
            // <Resource name="audience"
            //           options={{ label: 'Audiences' }}
            //           list={ AudienceList }
            //           create={ AudienceCreate }
            //           edit={ AudienceEdit }
            //           show={ AudienceShow }
            //           icon={ PeopleIcon }
            // />,
            // <Resource name="trigger"
            //           options={{ label: 'Triggers' }}
            //           list={ TriggerList }
            //           show={ TriggerShow }
            //           edit={ TriggerEdit }
            //           create={ TriggerCreate }
            //           icon={ EventIcon }
            // />,
            // <Resource name='messenger-user'
            //           options={{ label: 'Messenger users' }}
            //           list={ MessengerUserList }
            //           show={ MessengerUserShow }
            //           icon={ Person }
            // />,
        ]
    }}

</Admin>;

export default App;