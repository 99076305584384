import { GeoForm } from "./geo-integration-form";
import { SimpleForm, Create } from "react-admin"
import { GeoEdit } from "./edit";

export const GeoCreate = ({ permissions, ...props }) =>  (
    <Create { ...props }>
        <SimpleForm>
            <GeoForm/>
        </SimpleForm>
    </Create>
)