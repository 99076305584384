const profileUrl = `${process.env.REACT_APP_ACCOUNT_URL}/profile`

export const getIdentity = (forceUpdate = false) => {
  const oldUser = localStorage.getItem('user')

  if (oldUser && forceUpdate === false) {
    console.log(oldUser)
    return Promise.resolve(JSON.parse(oldUser))
  }

  const accessToken = localStorage.getItem('accessToken')

  if (!accessToken) {
    return Promise.reject()
  }

  const request = new Request(profileUrl, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }),
  });
  return fetch(request)
    .then(response => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }

      return response.json();
    })
    .then(user => {
      localStorage.setItem('user', JSON.stringify(user))

      return user
    })
}

export const setIdentity = (value) => {
  const accessToken = localStorage.getItem('accessToken')

  if (!accessToken) {
    return Promise.reject()
  }

  const request = new Request(profileUrl, {
    method: 'PUT',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }),
    body: JSON.stringify(value)
  });
  return fetch(request)
    .then(response => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }

      return response.json();
    })
    .then(user => {
      localStorage.setItem('user', JSON.stringify(user))

      return user
    })
}