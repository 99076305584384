import * as React from "react";
import {
    Edit, ReferenceField, ReferenceInput, SelectInput,
    SimpleForm, TextField,
} from 'react-admin';
import { PaymentForm } from "./payment-form";

export const PaymentEdit = ({ permissions, ...props }) => {
   return (
        <Edit { ...props }>
            <SimpleForm>
                <PaymentForm/>
                <ReferenceInput perPage="200" source="userId" reference="user"><SelectInput optionText="email"/></ReferenceInput>
            </SimpleForm>
        </Edit>
    )
}


