import * as React from "react";
import {
    Edit,
    TabbedForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    BooleanInput,
    ArrayInput,
    SimpleFormIterator,
    SelectArrayInput,
    FormTab, ReferenceArrayInput, AutocompleteArrayInput
} from 'react-admin';
import { Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { blue, red } from "@material-ui/core/colors";
import { RichText } from '../../base-components/rich-text';

const useStyles = makeStyles({
    input: {
        width: 400,
        '& -webkit-input-placeholder': {
            color: red
        }
    },
});

const languages = [
    { id: 'ru', name: 'Русский' },
    { id: 'en', name: 'Английский' },
    { id: 'az', name: 'Азербайджанский' },
    { id: 'uk', name: 'Украинский' },
    { id: 'hy', name: 'Армянский' }
]

export const MessengerEdit = ({ permissions, ...props }) => {
    return (
        <Edit {...props}>
            <TabbedForm>
                <FormTab label="general">
                    <TextInput source="messengerName" />
                    <TextInput source="botToken" />
                    <TextInput helperText="Текст, отображаемый до приветствия" label="Имя бота" source="botName" />
                    <RichText helperText="Текст, отображаемый после приветствия" label="Текст приветствия бота" source="botText" />
                    <RichText helperText="Текст, отображаемый по кнопке 'Помощь'" label="Помощь" source="helpText" />
                    <TextInput helperText="Сюда будут приходить письма о созданных заказах" label="Email, для заказов" source="email" />
                    <BooleanInput label="Пропускать выбор типа доставки" fullWidth source="hideDeliveryTypes" />
                    <BooleanInput label="Пропускать выбор организации" fullWidth source="selectFirst" />
                    <ReferenceInput perPage="200" source="userId" reference="user"><SelectInput optionText="email" /></ReferenceInput>
                </FormTab>

                <FormTab label="comments">
                    <RichText label="Текст запроса комментария от пользователя" helperText="Если не задано, выведется 'Хотите оставить коментарий к заказу (или уточнить детали доставки)?'" source="adminCommentText" />
                    <BooleanInput label="Требовать ввод комментария от пользователя" fullWidth source="adminCommentRequired" />
                    <ArrayInput label="Готовые варианты комментариев для пользователя" source="adminCommentOptions">
                        <SimpleFormIterator>
                            <TextInput label="Вариант комментария" />
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>

                {/*<FormTab label="payments">*/}
                {/*    /!*<TextInput source="successfulPaymentText" fullWidth/>*!/*/}
                {/*    /!*<TextInput source="paymentErrorText"/>*!/*/}
                {/*    <PaymentSystemInput source="paymentSystem"/>*/}
                {/*</FormTab>*/}

                <FormTab label="loyalty">
                    <BooleanInput label="Использовать бонусную оплату" helperText="Если не заполнить поля логина и пароля, будет использован аккаунт chat4.biz (услуга платная)" source="bonusesEnabled" />
                    <TextInput source="smscLogin" />
                    <TextInput source="smscPassword" />
                </FormTab>

                {/*<FormTab label="триггеры">*/}
                {/*    <ReferenceArrayInput source='triggers' reference="trigger">*/}
                {/*        <AutocompleteArrayInput allowNull helperText={ false } optionText="id" />*/}
                {/*    </ReferenceArrayInput>*/}
                {/*</FormTab>*/}

                <FormTab label="localization">
                    <SelectInput allowEmpty label="Язык бота по умолчанию" source="defaultLanguage" choices={languages} />
                    <SelectArrayInput allowEmpty label="Исключенные языки из списка" source="excludedLanguages" choices={languages} />
                    <BooleanInput label="Включить выбор языка в боте" source="enableLanguageSelection" />
                </FormTab>

            </TabbedForm>
        </Edit>
    )
};