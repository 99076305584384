import React, { useState } from 'react';
import { useForm } from 'react-final-form';
import {
  required,
  Button,
  SaveButton,
  TextInput,
  BooleanInput,
  useUpdate,
  useNotify,
  FormWithRedirect, TextField
} from 'react-admin';
import IconContentEdit from '@material-ui/icons/Edit';
import IconCancel from '@material-ui/icons/Cancel';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

export const TerminalGroupEditButton = ({ record, organizationId, onChange }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [update, { loading, error }] = useUpdate('organization', organizationId);
  const notify = useNotify();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    let terminalGroups = []

    let payload = {
      id: record.id,
      ...values
    }

    terminalGroups.push(payload)

    update(
      { 
        payload: { 
          data: {
            terminalGroups,
          }
        } 
      },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          // Update the comment form to target the newly created post
          // Updating the ReferenceInput value will force it to reload the available posts
          // form.change('post_id', data.id);
          onChange();
        },
        onFailure: ({ error }) => {
          notify(error.message, 'error');
        }
      }
    );
  };

  return (
    <>
      <Button onClick={handleClick} label="ra.action.edit">
        <IconContentEdit />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Edit terminal group"
      >
        <DialogTitle>Edit terminal group {record?.name || ""}</DialogTitle>

        <FormWithRedirect
          resource="terminalGroup"
          save={handleSubmit}
          record={record}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
              <>
                <DialogContent>
                  <BooleanInput
                    source="notForSelfService"
                    fullWidth
                    label="Выключить для самовывоза?"
                  />
                </DialogContent>
                <DialogContent>
                  <BooleanInput
                    source="notForDelivery"
                    fullWidth
                    label="Выключить для доставки?"
                  />
                </DialogContent>
                <DialogContent>
                  <BooleanInput
                    source="preferred"
                    fullWidth
                    label="Отдавать предпочтение данному терминалу?"
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    label="ra.action.cancel"
                    onClick={handleCloseClick}
                    disabled={loading}
                  >
                    <IconCancel />
                  </Button>
                  <SaveButton
                    handleSubmitWithRedirect={
                      handleSubmitWithRedirect
                    }
                    pristine={pristine}
                    saving={saving}
                    disabled={loading}
                  />
                </DialogActions>
              </>
            )}
        />
      </Dialog>
    </>
  );
}
