import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  BooleanInput,
  SelectInput,
  TextField,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
  useGetIdentity
} from 'react-admin';
import {
  required,
  minLength,
  email,
} from 'react-admin';

export const UserCreate = props =>
{
  console.log(`user props: ${ JSON.stringify(props) }`);
  const canCreateAdmin = props.permissions?.can('users.create.available.admin');

  const { identity } = useGetIdentity();

  const choices = [{
    id: 'user', name: 'user'
  }, {
    id: 'partner', name: 'partner'
  }];

  if (canCreateAdmin) {
    choices.push({
      id: 'admin', name: 'admin'
    })
  }

  return (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="firstname" />
      <TextInput source="lastname" />
      <TextInput source="email" validate={[email(), required()]} />
      <TextInput source="phone" />
      <TextInput source="organization" />
      <ReferenceInput perPage="200" filter={{ id_ne: props.id, role_ne: 'user' }} validate={required()} source="creatorId" reference="user">
        <SelectInput optionText="email"/>
      </ReferenceInput>
      <SelectInput source="role" choices={choices} />
      <TextInput source="password" validate={[minLength(3), required()]} />
    </SimpleForm>
  </Create>
)};