import * as React from "react";
import {
    List,
    Datagrid,
    DateField,
    TextField,
    ReferenceField,
    NumberField,
    BooleanField, useListContext
} from 'react-admin';
import { OrderListActions } from "../transport/list";


export const BizOrderDataGrid = ({ ...props }) => {
    const { filterValues, currentSort, perPage } = useListContext();

    const goToFilters = (record, reference) => {

        const { order, field } = currentSort;
        const newFilterValues = { ...filterValues, organizationId: [...(filterValues?.organizationId || [])] };

        if (newFilterValues?.organizationId?.length) {
            if (newFilterValues.organizationId.includes(record.organizationId)) {
                return "#";
            }
            newFilterValues.organizationId.push(record.organizationId);
        } else {
            newFilterValues.organizationId = [record.organizationId];
        }

        const resultFilters = {
            displayedFilters: JSON.stringify({ main: true }),
            filter: JSON.stringify(newFilterValues),
            order,
            sort: field,
            page: 1,
            perPage
        }

        const result = "?" + (new URLSearchParams(resultFilters)).toString();

        console.log(`filter result: ${ result }`);

        return result;
    }

    return (<Datagrid rowClick="show">
        <TextField source="id" />
        <TextField label="Номер" source="number" />
        <BooleanField label="Самовывоз?" source="isSelfService"/>
        <TextField label="Телефон" source="phone" />
        <TextField label="Статус" source="status" />
        <DateField label="Дата создания" showTime source="localCreationTime" />
        <NumberField label="Стоимость" source="sum" />
        <NumberField label="Доставка" source="deliveryPrice" />
        <ReferenceField link={ goToFilters } label="Организация" source="organizationId" reference="organization">
            <TextField source="adminName"/>
        </ReferenceField>
    </Datagrid>)
}

export const BizOrderList = ({ permissions, ...props }) => (
  <List actions={<OrderListActions />} sort={{ field: 'localCreationTime', order: 'DESC' }} {...props}>
      <BizOrderDataGrid/>
  </List>
);