import * as React from "react";
import {
    Show,
    TextField,

    TopToolbar,
    EditButton,
    SimpleShowLayout
} from 'react-admin';
import { SynchronizeListButton } from "./refresh";


const SystemActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <SynchronizeListButton record={ data }/>
        <EditButton basePath={ basePath } record={ data }/>
    </TopToolbar>
);

export const SystemShow = props => (

    <Show actions={ <SystemActions/> } { ...props }>
        <SimpleShowLayout>
            <TextField source="id"/>
            <TextField source="systemName"/>
            <TextField label="Transport login" source="login"/>
            <TextField label="Biz user" source="iikoBizUser"/>
        </SimpleShowLayout>
    </Show>
);