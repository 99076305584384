import React, { Component, useState } from 'react';
import { Query, Builder, BasicConfig, BasicFuncs, Utils as QbUtils } from 'react-awesome-query-builder';

// For AntDesign widgets only:
import AntdConfig from 'react-awesome-query-builder/lib/config/antd';
import 'antd/dist/antd.css'; // or import "react-awesome-query-builder/css/antd.less";
// For Material-UI widgets only:
import MaterialConfig from 'react-awesome-query-builder/lib/config/material';
import * as FuncsConfig from 'react-awesome-query-builder/lib/config/funcs';

import 'react-awesome-query-builder/lib/css/styles.css';
import 'react-awesome-query-builder/lib/css/compact_styles.css';
import { useInput } from "ra-core";
// import { Button } from "ra-ui-materialui";
import { showNotification, Button } from "react-admin";
import { connect } from "react-redux"; //optional, for more compact styles

// Choose your skin (ant/material/vanilla):
const InitialConfig = MaterialConfig //AntdConfig; // or MaterialConfig or BasicConfig

const _ = require('lodash');

// You need to provide your own config. See below 'Config format'
const config = {
    ...InitialConfig,
    settings: {
        ...InitialConfig.settings,
        valueSourcesInfo: {
            value: {
                label: "Value"
            },
            field: {
                label: "Field",
                widget: "field",
            },
            func: {
                label: "Function",
                widget: "func",
            }
        },
    },
    funcs: {
        ...BasicFuncs,
    },
    fields: {
        // bot: {
        //     label: 'Бот',
        //     type: '!group',
        //     subfields: {
        //
        //     }
        // },
        orders: {
            label: "Orders",
            type: "!group",
            mode: "array",
            conjunctions: ["AND", "OR"],
            showNot: true,
            operators: [
                // w/ operand - count
                "equal",
                "not_equal",
                "less",
                "less_or_equal",
                "greater",
                "greater_or_equal",
                "between",
                "not_between",

                // w/o operand
                "some",
                "all",
                "none",
            ],
            defaultOperator: "some",
            initialEmptyWhere: true, // if default operator is not in config.settings.groupOperators, true - to set no children, false - to add 1 empty

            subfields: {
                status: {
                    label: 'Статус заказа',
                    type: "select",
                    fieldSettings: {
                        listValues: ["Closed", "Cancelled", "Error", "RepeatOrderError"],
                    },
                    valueSources: ["value"],
                },
                localCreationTime: {
                    label: 'Время создания заказа',
                    type: "datetime",
                    valueSources: ['value', 'func'],
                    preferWidgets: ['datetime'],
                    excludeOperators: ['is_empty', 'is_not_empty']
                },
                sum: {
                    label: 'Есть заказ на сумму',
                    type: 'number',
                    fieldSettings: {
                        min: 0,
                    },
                    valueSources: ['value'],
                    preferWidgets: ['number'],
                }
            }
        },
        bizorders: {
            label: "Biz Orders",
            type: "!group",
            mode: "array",
            conjunctions: ["AND", "OR"],
            showNot: true,
            operators: [
                // w/ operand - count
                "equal",
                "not_equal",
                "less",
                "less_or_equal",
                "greater",
                "greater_or_equal",
                "between",
                "not_between",

                // w/o operand
                "some",
                "all",
                "none",
            ],
            defaultOperator: "some",
            initialEmptyWhere: true, // if default operator is not in config.settings.groupOperators, true - to set no children, false - to add 1 empty

            subfields: {
                status: {
                    label: 'Статус заказа',
                    type: "select",
                    fieldSettings: {
                        listValues: ["Closed", "Cancelled", "Error", "RepeatOrderError"],
                    },
                    valueSources: ["value"],
                },
                localCreationTime: {
                    label: 'Время создания заказа',
                    type: "datetime",
                    valueSources: ['value', 'func'],
                    preferWidgets: ['datetime'],
                    excludeOperators: ['is_empty', 'is_not_empty']
                },
                sum: {
                    label: 'Есть заказ на сумму',
                    type: 'number',
                    fieldSettings: {
                        min: 0,
                    },
                    valueSources: ['value'],
                    preferWidgets: ['number'],
                }
            }
        },


    },
    // funcs: {
    //     lower: {
    //         label: 'Lowercase',
    //         returnType: 'number',
    //         args: {
    //             str: {
    //                 type: 'number',
    //                 valueSources: ['value', 'field'],
    //             }
    //         }
    //     },
    // }
};


// You can load query value from your backend storage (for saving see `Query.onChange()`)
const defaultTree = QbUtils.loadTree({ type: "group", "id": QbUtils.uuid() });

const _DemoQueryBuilder = ({ showNotification, ...props }) => {
    const {
        input: { name, onChange: raOnChange, value, ...rest },
        meta: { touched, error },
        isRequired
    } = useInput(props);

    console.log(`VALUE: ${ JSON.stringify(value) }`);
    // console.log(`query builder props: ${ JSON.stringify(props) }`);

    const treeFromJSONLogic = QbUtils.loadFromJsonLogic(value, config);
    console.log(`treeFromJSONLogic: ${ JSON.stringify(treeFromJSONLogic) }`);

    const tree = value
        ? treeFromJSONLogic
        : defaultTree;

    // const [state, setState] = useState({
    //     tree: QbUtils.checkTree(tree, config),
    //     config: config
    // });

    const validTree = QbUtils.checkTree(tree, config)

    const onChange = (immutableTree, config) => {
        // Tip: for better performance you can apply `throttle` - see `examples/demo`
        // setState({ tree: immutableTree, config: config });

        // const jsonTree = QbUtils.getTree(immutableTree);
        // console.log(jsonTree);
        // `jsonTree` can be saved to backend, and later loaded to `queryValue`
        const logic = QbUtils.jsonLogicFormat(immutableTree, config)?.logic;
        if (!_.isEqual(value, logic)) {
            raOnChange(logic)
        }
    };

    const handleImport = () => {
        navigator.clipboard.readText().then((textLogic) => {
            const logic = JSON.parse(textLogic);
            if (logic) {
                showNotification(`Success: data imported from clipboard`)
                raOnChange(logic)
            } else {
                throw new Error();
            }
        }).catch((e) => {
            console.error(e)
            showNotification(`Error: could not import due to wrong query format`, 'warning')
        });
    }
    const handleExport = () => {
        navigator.clipboard.writeText(JSON.stringify(QbUtils.jsonLogicFormat(validTree, config)?.logic))
            .then(() => showNotification(`Success: data copied to clipboard`))
            .catch(() => showNotification(`Error: could not copy data to clipboard`, "warning"))
    }

    const renderBuilder = (props) => (
        <div className="query-builder-container" style={ { padding: "10px" } }>
            <div className="query-builder qb-lite">
                <Builder { ...props } />
            </div>
        </div>
    );

    return (
        <div>
            <Button label="Import from clipboard" onClick={ handleImport }/>
            <Button label="Export to clipboard" onClick={ handleExport }/>
            <Query
                { ...config }
                value={ validTree }
                onChange={ onChange }
                renderBuilder={ renderBuilder }
            />
            {/*  <div className="query-builder-result">*/ }
            {/*      <div>*/ }
            {/*          Query string:{ " " }*/ }
            {/*          <pre>*/ }
            {/*  { JSON.stringify(QbUtils.queryString(validTree, config)) }*/ }
            {/*</pre>*/ }
            {/*      </div>*/ }
            {/*      <div>*/ }
            {/*          MongoDb query:{ " " }*/ }
            {/*          <pre>*/ }
            {/*  { JSON.stringify(QbUtils.mongodbFormat(validTree, config)) }*/ }
            {/*</pre>*/ }
            {/*      </div>*/ }
            {/*      <div>*/ }
            {/*          SQL where:{ " " }*/ }
            {/*          <pre>*/ }
            {/*  { JSON.stringify(QbUtils.sqlFormat(validTree, config)) }*/ }
            {/*</pre>*/ }
            {/*      </div>*/ }
            {/*      <div>*/ }
            {/*          JsonLogic:{ " " }*/ }
            {/*          <pre>*/ }
            {/*  { JSON.stringify(QbUtils.jsonLogicFormat(validTree, config)) }*/ }
            {/*</pre>*/ }
            {/*      </div>*/ }
            {/*  </div>*/ }
        </div>
    );
};

export const DemoQueryBuilder = connect(null, { showNotification })(_DemoQueryBuilder)