import * as React from "react";
import {
    Edit,
    SimpleForm,
    ReferenceInput,
    SelectInput,
} from 'react-admin';
import { SystemForm } from "./systemForm"

export const SystemEdit = ({ permissions, ...props }) => (
        <Edit { ...props }>
            <SimpleForm>
                <SystemForm/>
                <ReferenceInput perPage="200" source="userId" reference="user"><SelectInput optionText="email"/></ReferenceInput>
            </SimpleForm>
        </Edit>
    )


