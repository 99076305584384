import { ReferenceField, TextField, List, Datagrid } from "react-admin";
import * as React from "react";

export const CategoryList = ({ permissions, ...props }) => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <TextField source="name"/>
            <ReferenceField source="messengerIntegrationId" reference="messenger">
                <TextField source="messengerName" />
            </ReferenceField>
            <ReferenceField source="userId" reference="user">
                <TextField source="email" />
            </ReferenceField>
        </Datagrid>
    </List>
)