import * as React from "react";
import { List, Datagrid, TextField, BooleanField, ReferenceField } from 'react-admin';

export const OrganizationList = props => (
    <List { ...props }>
        <Datagrid rowClick="show">
            <TextField source="id"/>
            <TextField source="adminName"/>
            <TextField source="adminRestaurantAddress" label="Restaurant address"/>
            <TextField source="phone"/>
            <ReferenceField reference='user' source='userId'>
                <TextField source="email"/>
            </ReferenceField>
            <BooleanField source="isHidden"/>
            <ReferenceField reference='organization' source='mirrorOrganizationId'>
                <TextField source="adminName"/>
            </ReferenceField>
        </Datagrid>
    </List>
);