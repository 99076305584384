import { FormDataConsumer, SelectInput, SimpleForm, TextInput, required } from "react-admin";
import * as React from "react";

export const SystemForm = ({permissions,  ...props}) => {
    return <div {...props}>
        <SelectInput source="systemName" choices={ [
            { id: 'iiko', name: 'iiko-transport' },
            { id: 'iiko-biz', name: 'iiko-biz' }
        ]} { ...props }/>
        <FormDataConsumer { ...props }>
            {
                ({ formData, ...rest }) => {
                    if (formData?.systemName === 'iiko') {
                        return <TextInput label="API key" validate={[required()]} source="login" { ...rest } />
                    } else if (formData?.systemName === 'iiko-biz') {
                        return <div { ...props }>
                            <TextInput label="API user" validate={[required()]} source="iikoBizUser"  { ...rest } />
                            <TextInput label="API password" validate={[required()]} source="iikoBizSecret" { ...rest }/>
                        </div>
                    }
                }
            }
        </FormDataConsumer>
    </div>
}