import { Datagrid, SelectField, TextField, List, ReferenceField } from "react-admin";
import * as React from "react";

export const GeoList = ({ permissions, ...props }) => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField fullWidth source="name"/>
            <SelectField source="provider" choices={ [
                { id: 'yandex', name: 'yandex' },
                { id: 'google', name: 'google' },
                { id: 'openstreetmap', name: 'open street map' },
            ]} { ...props }/>
            <TextField fullWidth source="apiKey"/>
            <TextField label='Локаль' fullWidth source="locale"/>
            <ReferenceField source="userId" reference="user"><TextField source="email" /></ReferenceField>
        </Datagrid>
    </List>
)