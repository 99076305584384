import { fetchUtils } from 'react-admin';

export const httpClient = (url, options = {}) => {
  console.log(`http options: ${JSON.stringify(options)}`);
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const accessToken = localStorage.getItem('accessToken');
  options.headers.set('Authorization', `Bearer ${accessToken}`);
  return fetchUtils.fetchJson(url, options);
};
