import React, { useState } from 'react';
import {
    Button,
    SaveButton,
    TextInput,
    useUpdate,
    useNotify,
    FormWithRedirect
} from 'react-admin';
import IconContentEdit from '@material-ui/icons/Edit';
import IconCancel from '@material-ui/icons/Cancel';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

export const ProductEditButton = ({ record, organizationId, onChange, parentGroup }) => {
    const [showDialog, setShowDialog] = useState(false);
    const [update, { loading, error }] = useUpdate('product', record?.id);
    const notify = useNotify();

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const handleSubmit = async values => {
        let payload = {
            id: record.id,
            ...values
        }

        console.log(`product update payload: ${JSON.stringify(payload)}`);

        update(
            {
                payload: {
                    id: payload.id,
                    organizationId,
                    parentGroup,
                    data: {
                        alias: payload.alias,
                        parentGroup,
                    }
                }
            },
            {
                onSuccess: ({ data }) => {
                    setShowDialog(false);
                    // Update the comment form to target the newly created post
                    // Updating the ReferenceInput value will force it to reload the available posts
                    // form.change('post_id', data.id);
                    onChange();
                },
                onFailure: ({ error }) => {
                    notify(error.message, 'error');
                }
            }
        );
    };

    return (
        <>
            <Button onClick={ handleClick } label="ra.action.edit">
                <IconContentEdit/>
            </Button>
            <Dialog
                fullWidth
                open={ showDialog }
                onClose={ handleCloseClick }
                aria-label="Edit product"
            >
                <DialogTitle>Edit product { record?.alias || "" }</DialogTitle>

                <FormWithRedirect
                    resource="product"
                    save={ handleSubmit }
                    record={ record }
                    render={ ({
                                  handleSubmitWithRedirect,
                                  pristine,
                                  saving
                              }) => (
                        <>
                            <DialogContent>
                                <TextInput
                                    source="alias"
                                    fullWidth
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    label="ra.action.cancel"
                                    onClick={ handleCloseClick }
                                    disabled={ loading }
                                >
                                    <IconCancel/>
                                </Button>
                                <SaveButton
                                    handleSubmitWithRedirect={
                                        handleSubmitWithRedirect
                                    }
                                    pristine={ pristine }
                                    saving={ saving }
                                    disabled={ loading }
                                />
                            </DialogActions>
                        </>
                    ) }
                />
            </Dialog>
        </>
    );
}
