
import React, { useState } from 'react';
import SyncIcon from '@material-ui/icons/Sync';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import { httpClient } from '../../providers/helpers/client'
import {
  Button,
  showNotification
} from 'react-admin';

const _SynchronizeListButton = ({ record, showNotification }) => {
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setLoading(true)
    httpClient(`${process.env.REACT_APP_INTEGRATIONS_URL}/system/${record.id}/refresh_organizations`, { method: 'POST' })
      .then(resp => {
        if (!resp?.json?.success) {
          throw new Error(resp.statusText)
        }
        showNotification('Success: organizations refreshed');
      })
      .catch((e) => {
        showNotification(`Error: could not refresh organizations`, 'warning')
      })
      .finally(() => {
        setLoading(false)
      });
  }

  return (
    <Button 
      onClick={handleClick} 
      label="synchronize organization list"
      disabled={loading}>
      { loading ? <CircularProgress size={20} /> : <SyncIcon /> }
    </Button>
  )
}

export const SynchronizeListButton = connect(null, { showNotification })(_SynchronizeListButton)