import * as React from "react";
import {
  Show,
  TabbedShowLayout,
  TextField,
  Tab,
  ReferenceManyField,
  Datagrid,
  Pagination,
  ShowButton,
  TopToolbar,
  ReferenceField,
  BooleanField,
  ListButton,
  useRecordContext,
} from "react-admin";
import { ProductEditButton } from "../products/edit";
import { useCallback, useState } from "react";

const GroupShowActions = ({ groupId, organizationId, data, ...props }) => {
  return (
    <TopToolbar>
      <ListButton
        to={`/organization/${organizationId}/show/1`}
        label="group list"
      />
      {/* <GroupEditButton record={ record }/> */}
    </TopToolbar>
  );
};

const ProductShowButton = ({ record, ...props }) => (
  <ShowButton
    to={`/product/${props.organizationId}/${record.parentGroup}/${record.id}/show`}
  />
);

export const GroupShow = (props) => {
  const [version, setVersion] = useState(0);
  const handleChange = useCallback(() => setVersion(version + 1), [version]);

  props = {
    ...props,
    basePath: props.match.url,
    id: decodeURIComponent(props.match.params.id),
    organizationId: decodeURIComponent(props.match.params.organizationId),
  };

  const toParentGroup = (record, reference) => {
    return `/${reference}/${props.organizationId}/${record.parentGroup}/show`;
  };

  return (
    <Show
      actions={
        <GroupShowActions
          organizationId={props.organizationId}
          groupId={props.id}
          data={props}
        />
      }
      {...props}
    >
      <TabbedShowLayout>
        <Tab label="summary">
          <ReferenceField
            source="parentGroup"
            reference="group"
            link={toParentGroup}
          >
            <TextField source="name" />
          </ReferenceField>
          <BooleanField source="isGroupModifier" />
          <TextField source="id" />
          <TextField source="name" />
          <TextField source="description" />
          <TextField source="additionalInfo" />
          <TextField source="seoDescription" />
          <TextField source="seoText" />
          <TextField source="seoKeywords" />
          <TextField source="seoTitle" />
          <TextField source="alias" />
        </Tab>
        <Tab label="products">
          <ReferenceManyField
            addLabel={false}
            reference="product"
            target="parentGroup"
            filter={{
              organizationId: props.organizationId,
              isDeleted: false,
            }}
            pagination={<Pagination />}
          >
            <Datagrid>
              <TextField source="name" />
              <ProductShowButton organizationId={props.organizationId} />
              <ProductEditButton
                organizationId={props.organizationId}
                parentGroup={props.id}
                onChange={handleChange}
              />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
